import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { TInventoryTransfer } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination, TRecordAction } from '@/core/types'
import { useRouter } from 'vue-router'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TInventoryTransfer>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const locationsFilters = ref<ToQueryStringFilters>({
    warehouse_id: [],
  })

  const router = useRouter()

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id'],
  })

  const locationsQueryParams = useToQueryString(toReactive(locationsFilters))

  const paginationModule = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: paginationModule.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
      locationsCatalog: locationsQueryParams.queryParams,
    },
    enableQuery: {
      loadRecord: false,
      warehousesCatalog: true,
      locationsCatalog: computed(
        () => !!locationsFilters.value.warehouse_id?.length,
      ),
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        paginationModule.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'id'],
  })

  const onAdd = async (payload: TInventoryTransfer) => {
    const transfer = await moduleQuery.addRecord.mutateAsync(payload)
    onRedirectToEdit(transfer)
  }

  const onEdit = async (payload: TInventoryTransfer) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onRedirectToEdit = (transfer: TInventoryTransfer) => {
    router.push({
      name: 'inventoryTransferDetail',
      params: {
        id: transfer.id,
      },
    })
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)
  const warehouses = computed(() => moduleQuery.warehouseCatalog.data?.value)
  const model = computed(() => moduleQuery.loadRecord?.data?.value)

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )
  const isLoadingWarehouses = computed(
    () => moduleQuery.warehouseCatalog.isLoading.value,
  )

  return {
    model,
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    paginationModule: toReactive(paginationModule),
    isLoading,
    warehouses,
    isLoadingWarehouses,
    locationsFilters,
    onAction,
    onEdit,
    onAdd,
  }
})

export { useProvideModule, useModule }
