import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { TInventory } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination } from '@/core/types'

const [useProvideModule, useModule] = createInjectionState(() => {
  const form = ref<TInventory>({})

  const materialsFilters = ref<ToQueryStringFilters>({
    param: '',
    status_id: [1],
    order_by: 'created_at,asc',
  })

  const filters = ref<ToQueryStringFilters>({
    param: '',
    id: [],
    status_id: [],
    relations: {
      stock: {
        warehouse_id: '',
      },
    },
    part_number: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,asc',
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id', 'part_number', 'metric.name'],
  })

  const materialsQueryParams = useToQueryString(toReactive(materialsFilters), {
    searchColumns: ['id', 'part_number', 'name'],
  })

  const paginationModule = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: paginationModule.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
      articlesCatalog: materialsQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        paginationModule.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)
  const warehouse = computed(() => moduleQuery.warehouseCatalog?.data?.value)
  const articles = computed(() => moduleQuery.articlesCatalog.data?.value)
  const isArticlesLoading = computed(
    () => moduleQuery.articlesCatalog.isLoading.value,
  )
  const isLoading = computed(() => moduleQuery.loadRecords.isLoading.value)

  return {
    tableData,
    filters,
    orderByOptions,
    form,
    isLoading,
    warehouse,
    materialsFilters,
    articles,
    isArticlesLoading,
    modulePagination: toReactive(paginationModule),
  }
})

export { useProvideModule, useModule }
