import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { TMovementRequest } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination, TRecordAction } from '@/core/types'
const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TMovementRequest>({})
  const modelId = ref<number>()
  const filters = ref<ToQueryStringFilters>({
    param: '',
    id: [],
    warehouse_id: [],
    dates: {
      created_at: [],
    },
    order_by: 'created_at,asc',
  })
  const materialsFilters = ref<ToQueryStringFilters>({
    param: '',
    status_id: [1],
    order_by: 'created_at,desc',
  })
  const stockFilters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    inventoriable_id: [],
    warehouse_id: [],
    order_by: 'created_at,desc',
  })
  const locationsFilters = ref<ToQueryStringFilters>({
    warehouse_id: [],
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id', 'article.name'],
  })

  const materialsQueryParams = useToQueryString(toReactive(materialsFilters), {
    searchColumns: ['id', 'part_number', 'name'],
  })

  const locationsQueryParams = useToQueryString(toReactive(locationsFilters))

  const stockQueryParams = useToQueryString(toReactive(stockFilters))

  const paginationModule = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: paginationModule.currentPage,
    modelId: computed(() => Number(modelId.value)),
    filters: {
      loadRecords: moduleQueryParams.queryParams,
      locationsCatalog: locationsQueryParams.queryParams,
      stockCatalog: stockQueryParams.queryParams,
      materials: materialsQueryParams.queryParams,
    },
    enableQuery: {
      articlesCatalog: true,
      warehousesCatalog: true,
      locationsCatalog: computed(
        () => !!locationsFilters.value.warehouse_id?.length,
      ),
      stockCatalog: computed(
        () =>
          !!stockFilters.value?.inventoriable_id.length &&
          !!stockFilters.value?.warehouse_id.length,
      ),
      loadUnitPrice: computed(() => !!modelId.value),
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        paginationModule.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const onAdd = async (payload: TMovementRequest) => {
    await moduleQuery.addRecord.mutateAsync(payload)
  }

  const onAddOutput = async (payload: TMovementRequest) => {
    await moduleQuery.addRecordOutput.mutateAsync(payload)
  }

  const onEdit = async (payload: TMovementRequest) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onDelete = async (payload: TMovementRequest) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onLoadUnitPrice = async (id: number) => {
    modelId.value = id
    await moduleQuery.loadUnitPrice.refetch().then((response) => {
      form.value.unit_price = response.data
    })
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const articles = computed(() => moduleQuery.articlesCatalog?.data?.value)
  const warehouses = computed(() => moduleQuery.warehouseCatalog?.data?.value)
  const locations = computed(() => moduleQuery.locationsCatalog?.data?.value)
  const stock = computed(() => moduleQuery.stockCatalog?.data?.value)

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.addRecordOutput.isLoading.value ||
      moduleQuery.actionRecord.isLoading.value,
  )
  const isLoadingArticles = computed(
    () => moduleQuery.articlesCatalog.isLoading.value,
  )
  const isLoadingWarehouses = computed(
    () => moduleQuery.warehouseCatalog.isLoading.value,
  )
  const isLoadingLocations = computed(
    () => moduleQuery.locationsCatalog.isLoading.value,
  )
  const isLoadingStock = computed(
    () => moduleQuery.stockCatalog.isLoading.value,
  )

  const isLoadingUnitPrice = computed(
    () => moduleQuery.loadUnitPrice.isLoading.value,
  )

  return {
    tableData,
    articles,
    locations,
    filters,
    orderByOptions,
    rows,
    form,
    warehouses,
    stock,
    isLoading,
    isLoadingWarehouses,
    isLoadingArticles,
    isLoadingLocations,
    isLoadingStock,
    modulePagination: toReactive(paginationModule),
    locationsFilters,
    materialsFilters,
    stockFilters,
    isLoadingUnitPrice,
    onAction,
    onDelete,
    onEdit,
    onAdd,
    onAddOutput,
    onLoadUnitPrice,
  }
})

export { useProvideModule, useModule }
