import api from '@/core/config/axios'
import { AxiosResponse } from 'axios'

export const createPrintEndpoint = (model: string, version?: string) => {
  const printRecord = async (id: number, format: string = 'pdf') => {
    try {
      const response = await api.get(`${version ?? ''}/${model}/${id}/print`, {
        responseType: 'blob',
      })

      download(response, format)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const download = (response: AxiosResponse, format: string) => {
    const filename = `${model}.${format}`

    const blob = new Blob([response.data], { type: 'application/octet-stream' })

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  return {
    printRecord,
  }
}
