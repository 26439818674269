import { RouteRecordRaw } from 'vue-router'
import { usersModuleRoutes } from '@/modules/users'
import { articlesModuleRoutes } from '@/modules/articles'
import { dashboardModuleRoutes } from '@/modules/dashboard'
import { purchaseOrdersModuleRoutes } from '@/modules/purchase-orders'
import { categoriesModuleRoutes } from '@/modules/categories'
import { umModuleRoutes } from '@/modules/unit-of-measurement'
// import { typesModuleRoutes } from '@/modules/types' -- deprecated
import { inventoriesModuleRoutes } from '@/modules/inventories'
import { historysModuleRoutes } from '@/modules/history'
import { receptionsModuleRoutes } from '@/modules/receptions'
import { suppliersModuleRoutes } from '@/modules/suppliers'
import { locationsModuleRoutes } from '@/modules/locations'
import { warehousesModuleRoutes } from '@/modules/warehouses'
import { movementRequestsModuleRoutes } from '@/modules/movement-request'
import { inventoryRelocationsModuleRoutes } from '@/modules/inventory-relocations'
import { inventoryTransfersModuleRoutes } from '@/modules/inventory-transfers'
const DashboardLayout = () =>
  import(
    /* webpackChunkName: "dashboard-layout" */ '@/core/layouts/DashboardLayout.vue'
  )

export const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
    component: DashboardLayout,
    meta: {
      auth: true,
    },
    children: [
      ...dashboardModuleRoutes,
      ...usersModuleRoutes,
      ...purchaseOrdersModuleRoutes,
      ...articlesModuleRoutes,
      ...categoriesModuleRoutes,
      ...umModuleRoutes,
      // ...typesModuleRoutes -- deprecated,
      ...inventoriesModuleRoutes,
      ...receptionsModuleRoutes,
      ...historysModuleRoutes,
      ...suppliersModuleRoutes,
      ...locationsModuleRoutes,
      ...warehousesModuleRoutes,
      ...movementRequestsModuleRoutes,
      ...inventoryRelocationsModuleRoutes,
      ...inventoryTransfersModuleRoutes,
    ],
  },
]
