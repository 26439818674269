import { useLocalStorage } from '@vueuse/core'
import { setAuthHeader } from '../config'
import { onBeforeMount } from 'vue'

export const useAppConfig = () => {
  const tokenPrefix = 'Bearer'
  const tokenStorage = useLocalStorage('auth_access_token', null)

  const checkToken = () => {
    const token = tokenStorage.value ? JSON.parse(tokenStorage.value) : null

    !token
      ? (tokenStorage.value = null)
      : setAuthHeader(`${tokenPrefix} ${token}`)
  }

  onBeforeMount(() => {
    checkToken()
  })
}
