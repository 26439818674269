import { createCrudEndpoints, createExportEndpoint } from '@/core/utils'
import { TWarehouse } from '.'
import { DataWithPagination } from '@/core/types'

export type TWarehouses = DataWithPagination<TWarehouse>

export const serviceConfig = {
  model: 'warehouses',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<TWarehouses, TWarehouse>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

export { moduleServiceHandlers, moduleExportHandlers }
