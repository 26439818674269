import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import {
  type TArticle,
  moduleServiceHandlers,
  type TArticles,
  serviceConfig,
  catalogServiceHandlers,
  TCategory,
  TType,
  TMetric,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig
const { categories, metrics, types } = serviceConfig.catalogs
export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, filters, handlers, enableQuery } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TArticle>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TArticle, AxiosError, TArticle>(
    (payload: TArticle) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TArticle, AxiosError, TArticle>(
    (payload: TArticle) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TArticle, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TArticles, AxiosError>({
    queryKey: [model, currentPage, filters?.loadRecords],
    queryFn: () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecords,
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      if (data?.meta) {
        handlers?.onDataChange?.(data.meta ?? defaultPagination)
      }
    },
  })

  const loadCategoryCatalog = useQuery<TCategory[]>(
    [model, categories],
    () => catalogServiceHandlers.categoriesCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
    },
  )

  const loadTypesCatalog = useQuery<TType[]>(
    [model, types],
    () => catalogServiceHandlers.typesCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
    },
  )

  const loadMetricsCatalog = useQuery<TMetric[]>(
    [model, metrics],
    () => catalogServiceHandlers.metricsCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    actionRecord,
    loadCategoryCatalog,
    loadTypesCatalog,
    loadMetricsCatalog,
  }
}
