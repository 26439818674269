import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from 'primevue/usetoast'
import { useParserHTTPErrors } from './useParserHTTPErrors'
import { useI18n } from 'vue-i18n'

export const useNotification = () => {
  const toast = useToast()
  const { t } = useI18n()

  const errorNotification = (settings: {
    summary?: string
    detail?: string
  }) => {
    toast.add({
      severity: 'error',
      summary: settings.summary,
      detail: settings.detail,
      closable: true,
      life: 3500,
    })
  }

  const successNotification = (settings: {
    summary?: string
    detail?: string
  }) => {
    toast.add({
      severity: 'success',
      summary: settings.summary,
      detail: settings.detail,
      closable: true,
      life: 3500,
    })
  }

  const errorHttpNotification = (error: AxiosError) => {
    const { parsedError } = useParserHTTPErrors(
      error.response as AxiosResponse<any, any>,
    )
    errorNotification({
      detail: parsedError.value.errors?.join(',') ?? '',
      summary: Array.isArray(parsedError.value.message)
        ? parsedError.value.message?.join(', ')
        : parsedError.value.message,
    })
  }

  const successWithActionNotification = (
    action: 'DELETED' | 'UPDATED' | 'ADDED' | 'DOWNLOADED',
    model: string,
  ) => {
    successNotification({
      summary: t(`${model}.MODEL`, 2),
      detail: t(`strings.CRUD_HELPER`, {
        attribute: t(`actions.${action}`),
      }),
    })
  }

  return {
    errorNotification,
    successNotification,
    errorHttpNotification,
    successWithActionNotification,
  }
}
