import { RouteRecordRaw } from 'vue-router'
const Login = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/Login.vue')
const PasswordRecovery = () =>
  import(
    /* webpackChunkName: "login-view" */ './views/pages/PasswordRecovery.vue'
  )
const PasswordRecoveryConfirmation = () =>
  import(
    /* webpackChunkName: "login-view" */ './views/pages/PasswordRecoveryConfirmation.vue'
  )

export const authModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: 'login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Sign in',
    },
  },
  {
    path: 'password-recovery',
    name: 'passwordRecovery',
    component: PasswordRecovery,
  },
  {
    path: 'password-recovery-confirmation',
    name: 'passwordRecoveryConfirmation',
    component: PasswordRecoveryConfirmation,
  },
]
