import api from '@/core/config/axios'
import type {
  ActionPayload,
  DeletePayload,
  TFilters,
  ShowPayload,
  UpdatePayload,
} from '../types'
import { AxiosRequestConfig } from 'axios'
import { toValue } from 'vue'

export const createCrudEndpoints = <TModelResponse, TModel>(
  model: string,
  version?: string,
) => {
  const loadRecords = async (params: TFilters): Promise<TModelResponse> => {
    const urlSearchParams = [
      `page=${toValue(params.page ?? 1)}`,
      toValue(params.dynamicFilters ?? ''),
    ]
      .filter(Boolean)
      .join('&')

    return api
      .get(`${version ?? ''}/${model}?${urlSearchParams}`)
      .then((response) => response.data)
  }

  const loadRecord = async (payload: ShowPayload): Promise<TModel> =>
    api
      .get(`${version ?? ''}/${model}/${payload.id}`)
      .then((response) => response.data)

  const addRecord = async (
    payload: TModel | FormData,
    config?: AxiosRequestConfig<TModel>,
  ): Promise<TModel> =>
    api
      .post(`${version ?? ''}/${model}`, payload, config)
      .then((response) => response.data)

  const editRecordFormData = async (
    payload: (TModel & UpdatePayload) | FormData,
    id?: number,
    config?: AxiosRequestConfig<TModel & UpdatePayload>,
  ): Promise<TModel> =>
    await api
      .post(`${version ?? ''}/${model}/${id}`, payload, config)
      .then((response) => response.data)

  const editRecord = async (
    payload: (TModel & UpdatePayload) | FormData,
    id?: number,
    config?: AxiosRequestConfig<TModel & UpdatePayload>,
  ): Promise<TModel> =>
    await api
      .put(`${version ?? ''}/${model}/${id}`, payload, config)
      .then((response) => response.data)

  const deleteRecord = async (payload: DeletePayload): Promise<void> =>
    await api
      .delete(`${version ?? ''}/${model}/${payload?.id}`)
      .then((response) => response.data)

  const deleteRecords = async (payload: DeletePayload): Promise<void> =>
    api
      .delete(`${version ?? ''}/${model}/delete`, {
        params: {
          ids: payload.ids,
        },
      })
      .then((response) => response.data)

  const actionRecord = async (payload: ActionPayload): Promise<TModel> =>
    api
      .put(
        `${version ?? ''}/${model}/${payload.id}/${payload.action}`,
        payload?.data,
      )
      .then((response) => response.data)

  const importRecords = async (payload: FormData): Promise<void> =>
    api.post(`${version ?? ''}/${model}-import`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  return {
    loadRecords,
    loadRecord,
    addRecord,
    editRecord,
    editRecordFormData,
    deleteRecord,
    deleteRecords,
    actionRecord,
    importRecords,
  }
}
