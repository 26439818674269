import axios, { AxiosError, AxiosResponse } from 'axios'

const baseErrors = {
  INVALID_TOKEN: 'token is invalid',
  UNAUTHENTICATED: 'Unauthenticated.',
  TOKEN_BLACKLISTED: 'The token has been blacklisted',
}

// Create Instances
const api = axios.create({
  headers: {
    ...(import.meta.env.VITE_ENVIROMENT === 'alpha'
      ? { 'ngrok-skip-browser-warning': 69420 }
      : {}),
  },
})

api.interceptors.request.use(
  (config) => {
    const tokenStorage = localStorage.getItem('auth_access_token')
    const token = tokenStorage ? JSON.parse(tokenStorage) : null

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (
      typeof error.response === 'undefined' &&
      error.config?.url?.includes('/auth/user')
    ) {
      deleteCookie('auth_access_token')
      window.localStorage.removeItem('auth_access_token')
      removeAuthHeader()
      window.location.reload()
    }

    if (error.response?.data) {
      Object.values(error.response?.data).map((value: any) => {
        if (Object.values(baseErrors).includes(value)) {
          deleteCookie('auth_access_token')
          window.localStorage.removeItem('auth_access_token')
          removeAuthHeader()
          window.location.reload()
        }
      })
    }

    return Promise.reject(error)
  },
)

// Headers
export const setAuthHeader = (token: string) => {
  api.defaults.headers.common['Authorization'] = token
}

export const removeAuthHeader = () => {
  delete api.defaults.headers.common['Authorization']
}

export const setBaseUrl = (baseUrl: string) => {
  api.defaults.baseURL = baseUrl
}

const deleteCookie = (name: string) => {
  const domain = document.location.hostname
  document.cookie =
    name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=' + domain
}

export default api
