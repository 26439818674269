import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import {
  type TLocation,
  moduleServiceHandlers,
  type TLocations,
  serviceConfig,
  catalogServiceHandlers,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { TWarehouse } from '@/modules/warehouses'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, filters, handlers, enableQuery } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TLocation>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TLocation, AxiosError, TLocation>(
    (payload: TLocation) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TLocation, AxiosError, TLocation>(
    (payload: TLocation) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TLocation, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TLocations, AxiosError>({
    queryKey: [model, currentPage, filters?.loadRecords],
    queryFn: () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecords,
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      if (data?.meta) {
        handlers?.onDataChange?.(data.meta ?? defaultPagination)
      }
    },
  })

  const warehouseCatalog = useQuery<TWarehouse[]>(
    [serviceConfig.catalogs.warehouses],
    () => catalogServiceHandlers.warehouseCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.warehouseCatalog,
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    actionRecord,
    warehouseCatalog,
  }
}
