import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN } = authRoles

const PurchaseOrders = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

const PurchaseOrdersDetail = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/detail.vue')

export const purchaseOrdersModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/purchase-orders',
    name: 'purchaseOrders',
    component: PurchaseOrders,
    meta: {
      title: 'Órdenes de compra',
      auth: {
        roles: [ADMIN],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/purchase-orders/:id',
    name: 'purchaseOrdersDetail',
    component: PurchaseOrdersDetail,
    meta: {
      title: 'Órden de compra',
      auth: {
        roles: [ADMIN],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
