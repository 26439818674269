import { computed, ref } from 'vue'
import { createInjectionState, toReactive, useLocalStorage } from '@vueuse/core'
import {
  useOrderByFilter,
  usePagination,
  useToQueryString,
  ToQueryStringFilters,
} from '@/core'
import type { TReception } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination, TRecordAction } from '@/core/types'
import { useRoute, useRouter } from 'vue-router'
import { useAuth } from 'vue-auth3'
import { TUser } from '@/modules/auth'

const [useProvideModule, useModule] = createInjectionState(() => {
  const router = useRouter()
  const route = useRoute()
  const auth = useAuth()

  const lsFilters = useLocalStorage<ToQueryStringFilters | null>(
    `${route.name?.toString()}_${auth.user<TUser>()?.id}`,
    {},
  )

  const rows = ref<number[]>([])
  const form = ref<TReception>({})
  const filters = ref<ToQueryStringFilters>({
    param: lsFilters.value?.param ?? '',
    id: lsFilters.value?.id ?? [],
    purchase_order_id: lsFilters.value?.purchase_order_id ?? [],
    status_id: lsFilters.value?.status_id ?? [],
    dates: {
      created_at: [],
      ...lsFilters.value?.dates,
    },
    order_by: lsFilters.value?.order_by ?? 'created_at,asc',
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id'],
    persistFilters: true,
    persistKey: `${route.name?.toString()}_${auth.user<TUser>()?.id}`,
  })

  const paginationModule = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: paginationModule.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: computed(() => !!moduleQueryParams.queryParams.value),
      loadRecord: false,
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        paginationModule.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'id'],
  })

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onRedirectToEdit = (order: TReception) => {
    router.push({
      name: 'purchaseOrdersDetail',
      params: {
        id: order.id,
      },
    })
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const isLoading = computed(() => moduleQuery.loadRecords.isLoading.value)

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    paginationModule: toReactive(paginationModule),
    isLoading,
    onAction,
    onRedirectToEdit,
  }
})

export { useProvideModule, useModule }
