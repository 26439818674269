import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, WAREHOUSEMAN } = authRoles

const InventoryRelocations = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

const InventoryRelocationsDetail = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/detail.vue')

export const inventoryRelocationsModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/inventory-relocations',
    name: 'inventoryRelocations',
    component: InventoryRelocations,
    meta: {
      title: 'Reubicar material',
      auth: {
        roles: [ADMIN, WAREHOUSEMAN],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/inventory-relocations/:id',
    name: 'inventoryRelocationsDetail',
    component: InventoryRelocationsDetail,
    meta: {
      title: 'Reubicar material',
      auth: {
        roles: [ADMIN],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
