import type {
  BaseServiceOptions,
  Pagination,
  TImportFile,
  TRecordAction,
} from '@/core/types'
import {
  type TUser,
  moduleServiceHandlers,
  moduleExportHandler,
  type TUserForm,
  type TUsers,
  serviceConfig,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TUser>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TUser>(
    (payload) => moduleServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, user) => serviceHandlers.deleteRecord(user),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecords = useMutation<void, AxiosError, Array<number>>(
    (payload) => moduleServiceHandlers.deleteRecords({ ids: payload }),
    {
      onSuccess: (_, ids) => serviceHandlers.deleteRecords(ids),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TUser, AxiosError, TUser & TUserForm>(
    (payload: TUser) => moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TUser, AxiosError, TUser & TUserForm>(
    (payload: TUser) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TUser, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const importRecords = useMutation<void, AxiosError, TImportFile>(
    (payload: TImportFile) => {
      const formData = new FormData()
      formData.append('importFile', payload.file)
      return moduleServiceHandlers.importRecords(formData)
    },
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TUsers, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onDataChange?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const exportRecords = async () =>
    await moduleExportHandler.exportRecords({
      dynamicFilters: filters?.loadRecords,
    })

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecords,
    deleteRecord,
    importRecords,
    actionRecord,
    exportRecords,
  }
}
