import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import {
  type TMetric,
  moduleServiceHandlers,
  type TMetrics,
  serviceConfig,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, filters, handlers, enableQuery } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TMetric>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TMetric, AxiosError, TMetric>(
    (payload: TMetric) => moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TMetric, AxiosError, TMetric>(
    (payload: TMetric) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TMetric, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TMetrics, AxiosError>({
    queryKey: [model, currentPage, filters?.loadRecords],
    queryFn: () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecords,
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      if (data.meta) {
        handlers?.onDataChange?.(data?.meta ?? defaultPagination)
      }
    },
  })

  return {
    loadRecords,
    addRecord,
    editRecord,
    actionRecord,
  }
}
