interface Settings {
  ENABLE_MSW?: boolean
  DEVELOPMENT_URL(slug: string): string
}

export type ENV = 'development' | 'alpha' | 'beta' | 'production'

const sharedKeys = {
  // CUSTOM_SHARED_KEY: '123123123123123',
}

export const VUE_APP: Record<ENV, Settings> = {
  development: {
    ...sharedKeys,
    ENABLE_MSW: true,
    DEVELOPMENT_URL: (slug: string) => `dev-${slug}.local`,
  },

  alpha: {
    ...sharedKeys,
    DEVELOPMENT_URL: (slug: string) => `${slug}.local`,
  },

  beta: {
    ...sharedKeys,
    DEVELOPMENT_URL: (slug: string) => `${slug}.local`,
  },

  production: {
    ...sharedKeys,
    DEVELOPMENT_URL: (slug: string) => `${slug}.local`,
  },
}

export const settings: Settings =
  VUE_APP[(import.meta.env?.MODE as ENV) ?? 'development']
