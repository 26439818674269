import type { BaseServiceOptions, Pagination } from '@/core/types'
import {
  type TInventoryTransferDetail,
  moduleDetailServiceHandlers,
  serviceConfig,
  catalogServiceHandlers,
} from '../../..'
import { useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { TStocks } from '@/modules/movement-request'

const { model } = serviceConfig.details

export const useInventoryTransferDetailsQuery = (
  options: BaseServiceOptions<Pagination>,
) => {
  const { currentPage, enableQuery, filters, modelId } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TInventoryTransferDetail>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TInventoryTransferDetail>(
    (payload) =>
      moduleDetailServiceHandlers.deleteRecord({
        modelChildId: payload.id,
        modelId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<
    TInventoryTransferDetail,
    AxiosError,
    TInventoryTransferDetail
  >(
    (payload: TInventoryTransferDetail) =>
      moduleDetailServiceHandlers.editRecord(payload, {
        modelChildId: payload.id,
        modelId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<
    TInventoryTransferDetail,
    AxiosError,
    TInventoryTransferDetail
  >(
    (payload: TInventoryTransferDetail) =>
      moduleDetailServiceHandlers.addRecord(payload, { modelId }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TInventoryTransferDetail[], AxiosError>({
    queryKey: [model, filters?.loadRecords],
    queryFn: ({ pageParam }) =>
      moduleDetailServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: pageParam,
        modelId,
      }),
    onError: (error) => errorHttpNotification(error),
    select: (data) =>
      data.map((record) => ({
        ...record,
        quantity: Number(record.quantity ?? 0).toFixed(2),
      })),
    enabled: enableQuery?.loadRecords,
    refetchOnWindowFocus: false,
  })

  const stockCatalog = useQuery<TStocks[]>(
    [serviceConfig.catalogs.stock, filters?.stockCatalog],
    () =>
      catalogServiceHandlers.stockCatalog.loadCatalog({
        dynamicFilters: filters?.stockCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.stockCatalog,
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecord,
    stockCatalog,
  }
}
