import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createNestedCrudEndpoints,
} from '@/core/utils'
import {
  TInventoryTransfer,
  TInventoryTransferDetail,
  TWarehouse,
  TStock,
} from '.'
import { DataWithPagination } from '@/core/types'

export type TInventoryTransfers = DataWithPagination<TInventoryTransfer>

export const serviceConfig = {
  model: 'inventory-transfers',
  version: 'v1',
  details: {
    model: 'details',
    version: 'v1',
  },
  catalogs: {
    warehouses: {
      model: 'warehouses',
      version: 'v1',
    },
    stock: {
      model: 'stock',
      version: 'v1',
    },
  },
}

const { warehouses, stock } = serviceConfig.catalogs
const { model: modelDetails, version: versionDetails } = serviceConfig.details

const moduleServiceHandlers = createCrudEndpoints<
  TInventoryTransfers,
  TInventoryTransfer
>(serviceConfig.model, serviceConfig.version)

const moduleDetailServiceHandlers = createNestedCrudEndpoints<
  TInventoryTransferDetail[],
  TInventoryTransferDetail
>(serviceConfig.model, modelDetails, versionDetails)

const warehouseCatalog = createCatalogEndpoint<TWarehouse[]>(
  warehouses.model,
  warehouses.version,
)

const stockCatalog = createCatalogEndpoint<TStock[]>(stock.model, stock.version)

const catalogServiceHandlers = {
  warehouseCatalog,
  stockCatalog,
}

export {
  moduleServiceHandlers,
  catalogServiceHandlers,
  moduleDetailServiceHandlers,
}
