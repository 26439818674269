import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import {
  type TMovementRequest,
  moduleServiceHandlers,
  moduleExportHandlers,
  type TMovementRequests,
  serviceConfig,
  catalogServiceHandlers,
  TStocks,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { TWarehouse } from '@/modules/warehouses'
import { TArticle } from '@/modules/articles'
import { TLocation } from '@/modules/locations'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, modelId, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TMovementRequest>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TMovementRequest>(
    (payload) => moduleServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, movementRequest) =>
        serviceHandlers.deleteRecord(movementRequest),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecords = useMutation<void, AxiosError, Array<number>>(
    (payload) => moduleServiceHandlers.deleteRecords({ ids: payload }),
    {
      onSuccess: (_, ids) => serviceHandlers.deleteRecords(ids),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<
    TMovementRequest,
    AxiosError,
    TMovementRequest
  >(
    (payload: TMovementRequest) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TMovementRequest, AxiosError, TMovementRequest>(
    (payload: TMovementRequest) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) =>
        serviceHandlers.addRecord({
          ...payload,
          quantity: Number(payload.quantity).toFixed(2),
        }),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecordOutput = useMutation<
    TMovementRequest,
    AxiosError,
    TMovementRequest
  >(
    (payload: TMovementRequest) =>
      moduleServiceHandlers.addRecordOutput(payload),
    {
      onSuccess: (payload) =>
        serviceHandlers.addRecord({
          ...payload,
          quantity: Number(payload.quantity).toFixed(2),
        }),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadUnitPrice = useQuery<number, AxiosError>({
    queryKey: [model, modelId],
    queryFn: ({ queryKey }) => {
      return moduleServiceHandlers.loadUnitPrice({
        id: Number(queryKey[1]),
      })
    },
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadUnitPrice,
    onError: (error) => errorHttpNotification(error),
  })

  const actionRecord = useMutation<TMovementRequest, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TMovementRequests, AxiosError>({
    queryKey: [model, currentPage, filters?.loadRecords],
    queryFn: () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecords,
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      if (data.meta) {
        handlers?.onDataChange?.(data.meta ?? defaultPagination)
      }
    },
    select: (data) => ({
      ...data,
      data: data.data.map((record) => ({
        ...record,
        unit_price: Number(record?.unit_price ?? 0),
      })),
    }),
  })

  const exportRecords = async () =>
    await moduleExportHandlers.exportRecords({
      dynamicFilters: filters?.loadRecords,
    })

  const articlesCatalog = useQuery<TArticle[], AxiosError>({
    queryKey: [serviceConfig.catalogs.articles, filters?.materials],
    queryFn: () =>
      catalogServiceHandlers.articlesCatalog.loadCatalog({
        dynamicFilters: filters?.materials,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.articlesCatalog,
  })

  const warehouseCatalog = useQuery<TWarehouse[]>(
    [serviceConfig.catalogs.warehouses],
    () => catalogServiceHandlers.warehouseCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.warehouseCatalog,
    },
  )
  const locationsCatalog = useQuery<TLocation[]>(
    [serviceConfig.catalogs.locations, filters?.locationsCatalog],
    () =>
      catalogServiceHandlers.locationsCatalog.loadCatalog({
        dynamicFilters: filters?.locationsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.locationsCatalog,
    },
  )

  const stockCatalog = useQuery<TStocks[]>(
    [serviceConfig.catalogs.stock, filters?.stockCatalog],
    () =>
      catalogServiceHandlers.stockCatalog.loadCatalog({
        dynamicFilters: filters?.stockCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.stockCatalog,
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecords,
    deleteRecord,
    actionRecord,
    addRecordOutput,
    loadUnitPrice,
    warehouseCatalog,
    locationsCatalog,
    articlesCatalog,
    stockCatalog,
    exportRecords,
  }
}
