import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createNestedCrudEndpoints,
} from '@/core/utils'
import { TInventoryRelocation, TInventoryRelocationDetail, TWarehouse } from '.'
import { DataWithPagination } from '@/core/types'
import { TLocations } from '../locations'
import { TStocks } from '../movement-request'

export type TInventoryRelocations = DataWithPagination<TInventoryRelocation>

export const serviceConfig = {
  model: 'inventory-relocations',
  version: 'v1',
  details: {
    model: 'details',
    version: 'v1',
  },
  catalogs: {
    warehouses: {
      model: 'warehouses',
      version: 'v1',
    },
    locations: {
      model: 'locations',
      version: 'v1',
    },
    stock: {
      model: 'stock',
      version: 'v1',
    },
  },
}

const { warehouses, locations, stock } = serviceConfig.catalogs
const { model: modelDetails, version: versionDetails } = serviceConfig.details

const moduleServiceHandlers = createCrudEndpoints<
  TInventoryRelocations,
  TInventoryRelocation
>(serviceConfig.model, serviceConfig.version)

const moduleDetailServiceHandlers = createNestedCrudEndpoints<
  TInventoryRelocationDetail[],
  TInventoryRelocationDetail
>(serviceConfig.model, modelDetails, versionDetails)

const warehouseCatalog = createCatalogEndpoint<TWarehouse[]>(
  warehouses.model,
  warehouses.version,
)
const locationsCatalog = createCatalogEndpoint<TLocations[]>(
  locations.model,
  locations.version,
)

const stockCatalog = createCatalogEndpoint<TStocks[]>(
  stock.model,
  stock.version,
)

const catalogServiceHandlers = {
  warehouseCatalog,
  locationsCatalog,
  stockCatalog,
}

export {
  moduleServiceHandlers,
  catalogServiceHandlers,
  moduleDetailServiceHandlers,
}
