import { createApp } from 'vue'
import App from './App.vue'
import { settings } from './core/settings/DynamicSettings'
import { createPinia } from 'pinia'
import { setBaseUrl, auth, i18n } from './core/config'
import router from './core/config/router'
const app = createApp(App)
const pinia = createPinia()
import 'primeicons/primeicons.css'
import { loadPrimeComponents } from './core/config/theme'
import { VueQueryPlugin } from '@tanstack/vue-query'

setupApp().then(() => {
  app
    .use(router)
    .use(auth)
    .use(pinia)
    .use(i18n)
    .use(VueQueryPlugin)
    .mount('#app')
  loadPrimeComponents(app)
})

async function setupApp() {
  if (settings.ENABLE_MSW) {
    const { worker } = await import('./mocks/browser')
    worker.start({ onUnhandledRequest: 'bypass' })
    setBaseUrl(`${window.origin}/api`)
  } else {
    setBaseUrl(import.meta.env.VITE_API_URL)
  }
}
