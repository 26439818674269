import { createCrudEndpoints, createExportEndpoint } from '@/core/utils'
import { TSupplier } from '.'
import { DataWithPagination } from '@/core/types'

export type TSuppliers = DataWithPagination<TSupplier>

export const serviceConfig = {
  model: 'suppliers',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<TSuppliers, TSupplier>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

export { moduleServiceHandlers, moduleExportHandlers }
