import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  defaultStatuses,
  recordActions,
  useToQueryString,
} from '@/core'
import type { TReception } from '../../..'
import { TBaseFileReception, TRecordAction } from '@/core/types'
import { useRoute, useRouter } from 'vue-router'
import { useReceptionDetailsQuery } from '../query/useReceptionDetailsQuery'
import { useReceptionDetailsStore } from '../../store/details'
import { storeToRefs } from 'pinia'
import { useModuleQuery } from '../query/useModuleQuery'
import { useOCDetailsQuery } from '../query/useOCDetailsQuery'

const [useProvideReceptionModule, useReceptionModule] = createInjectionState(
  () => {
    const ocSelected = ref<number | string>()
    const form = ref<TReception & TBaseFileReception>({})
    const purchaseOrdersFilters = ref<ToQueryStringFilters>({
      param: '',
      status_id: [4],
      id: [],
      order_by: 'created_at,desc',
    })

    const store = useReceptionDetailsStore()
    const { details } = storeToRefs(store)

    const route = useRoute()
    const router = useRouter()
    const isEdit = computed(() => route.name === 'receptionsDetails')

    const ocQueryParams = useToQueryString(toReactive(purchaseOrdersFilters), {
      searchColumns: ['id', 'supplier.name'],
    })

    const receptionsQuery = useModuleQuery({
      modelId: computed(() => route.params?.id?.toString()),
      enableQuery: {
        loadRecords: false,
        loadRecord: computed(() => !!route.params?.id),
      },
      handlers: {
        onLoadRecord: (reception) => {
          if (
            isApplied.value &&
            reception?.status?.name === defaultStatuses.APPLIED
          ) {
            router.replace({
              name: 'receptionsDetails',
              params: { id: reception.id },
            })
          }

          if (reception?.purchase_order?.id) {
            purchaseOrdersFilters.value.id = [reception.purchase_order?.id]
          }

          form.value = { ...reception }
        },
      },
    })

    const ocDetailsQuery = useOCDetailsQuery({
      modelId: computed(() => ocSelected.value?.toString() ?? ''),
      enableQuery: {
        loadRecords: computed(() => !!ocSelected.value),
      },
    })

    const receptionDetailQuery = useReceptionDetailsQuery({
      modelId: computed(() => route.params?.id?.toString()),
      enableQuery: {
        loadRecords: computed(
          () => !receptionsQuery.loadRecord.isLoading.value && isEdit.value,
        ),
        purchaseOrdersCatalog: computed(() => {
          if (isEdit.value) {
            return (
              !!receptionsQuery.loadRecord.isSuccess.value &&
              !!purchaseOrdersFilters.value?.id?.[0]
            )
          }
          return true
        }),
      },
      filters: {
        purchaseOrdersCatalog: ocQueryParams.queryParams,
      },
    })

    const onAdd = async (payload: TReception & TBaseFileReception) => {
      const reception = await receptionsQuery.addRecord.mutateAsync({
        ...payload,
      })
      redirectTo(reception)
    }

    const onEdit = async (payload: TReception & TBaseFileReception) => {
      await receptionsQuery.editRecord.mutateAsync({
        ...payload,
      })
      await receptionsQuery.loadRecord.refetch()
    }

    const onAction = async (payload: TRecordAction) => {
      await receptionsQuery.actionRecord.mutateAsync(payload)
      await receptionDetailQuery.loadRecords.refetch()
    }

    const redirectTo = (payload: TReception & TBaseFileReception) => {
      router.replace({
        name: 'receptions',
        query: {
          reception: payload.id,
          action: 'create',
          redirect: 'receptionsDetails',
        },
      })
    }

    const isApplied = computed(
      () => route.query.action === recordActions.applied && isEdit.value,
    )
    const model = computed(() => receptionsQuery.loadRecord?.data?.value)
    const purchaseOrdersCatalog = computed(
      () => receptionDetailQuery.purchaseOrdersCatalog.data.value,
    )
    const warehouseCatalog = computed(
      () => receptionDetailQuery.warehouseCatalog.data.value,
    )
    const isOCCatalogLoading = computed(
      () => receptionDetailQuery.purchaseOrdersCatalog.isLoading.value,
    )
    const isWarehouseCatalogLoading = computed(
      () => receptionDetailQuery.warehouseCatalog.isLoading.value,
    )
    const isDetailsLoading = computed(() =>
      isEdit.value
        ? receptionDetailQuery.loadRecords.isLoading.value
        : ocDetailsQuery.loadRecords.isLoading.value && !!ocSelected.value,
    )
    const isLoading = computed(() => {
      if (isEdit.value) {
        return (
          receptionsQuery.addRecord.isLoading.value ||
          receptionsQuery.editRecord.isLoading.value ||
          receptionsQuery.loadRecord.isLoading.value ||
          receptionsQuery.loadRecord.isFetching.value
        )
      }

      return isWarehouseCatalogLoading.value
    })

    return {
      ocSelected,
      model,
      purchaseOrdersFilters,
      form,
      isEdit,
      isLoading,
      details,
      isDetailsLoading,
      purchaseOrdersCatalog,
      warehouseCatalog,
      isOCCatalogLoading,
      isApplied,
      onAdd,
      onEdit,
      onAction,
    }
  },
)

export { useProvideReceptionModule, useReceptionModule }
