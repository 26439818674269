import type { BaseServiceOptions, Pagination } from '@/core/types'
import { catalogServiceHandlers, serviceConfig } from '../../..'
import { useNotification } from '@/core'
import { useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { TPurchaseOrderDetail } from '@/modules/purchase-orders'
import { useReceptionDetailsStore } from '../../store/details'

const { model } = serviceConfig.purchaseOrderDetails
const { ocDetailsHandlers } = catalogServiceHandlers
export const useOCDetailsQuery = (options: BaseServiceOptions<Pagination>) => {
  const { enableQuery, modelId } = options

  const { errorHttpNotification } = useNotification()
  const store = useReceptionDetailsStore()

  const loadRecords = useQuery<TPurchaseOrderDetail[], AxiosError>({
    queryKey: [model, modelId],
    queryFn: ({ pageParam }) =>
      ocDetailsHandlers.loadRecords({
        page: pageParam,
        modelId,
      }),
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      store.setDetails(data)
    },
    select: (data) =>
      data.map((record) => ({
        ...record,
        quantity: Number(record?.quantity ?? 0).toFixed(2),
        to_receive: Number(record?.to_receive ?? 0).toFixed(2),
        quantity_balance: Number(record?.quantity_balance ?? 0).toFixed(2),
      })),
    enabled: enableQuery?.loadRecords,
    refetchOnWindowFocus: false,
  })

  return {
    loadRecords,
  }
}
