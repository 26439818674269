import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import { useModuleQuery } from '../query/useModuleQuery'
import { useRoute } from 'vue-router'
import { useInventoryRelocationDetailsQuery } from '../query/useInventoryRelocationDetailsQuery'
import { ToQueryStringFilters, usePagination, useToQueryString } from '@/core'
import { TInventoryRelocationDetail } from '@/modules/inventory-relocations/types'
import { TRecordAction } from '@/core/types'
const [useInventoryRelocationModule, useInventoryRelocationsDetailModule] =
  createInjectionState(() => {
    const route = useRoute()
    const form = ref<TInventoryRelocationDetail>({})
    const stockFilters = ref<ToQueryStringFilters>({
      param: '',
      warehouse_id: [],
      order_by: 'created_at,desc',
    })
    const paginationDetails = usePagination()

    const stockQueryParams = useToQueryString(toReactive(stockFilters))

    const moduleQuery = useModuleQuery({
      modelId: computed(() => route.params?.id.toString()),
      enableQuery: {
        loadRecords: false,
        loadRecord: computed(() => !!route.params?.id),
      },
    })

    const model = computed(() => ({
      ...moduleQuery.loadRecord?.data?.value,
    }))

    const inventoryRelocationsDetailQuery = useInventoryRelocationDetailsQuery({
      modelId: computed(() => route.params?.id.toString()),
      currentPage: paginationDetails.currentPage,
      enableQuery: {
        loadRecords: computed(() => Boolean(route.params?.id)),
        stockCatalog: computed(
          () => !!(stockQueryParams.params.value.length > 1),
        ),
      },
      filters: {
        stockCatalog: computed(() =>
          [
            stockQueryParams.queryParams.value,
            ...(model.value.destinationLocation?.id
              ? [`location_id!=${model.value.destinationLocation?.id}`]
              : []),
          ].join('&'),
        ),
      },
      handlers: {
        onDataChange: (pagination) => {
          paginationDetails.setPagination(pagination)
        },
      },
    })

    const onAdd = async (payload: TInventoryRelocationDetail) => {
      await inventoryRelocationsDetailQuery.addRecord.mutateAsync(payload)
      await inventoryRelocationsDetailQuery.loadRecords.refetch()
    }

    const onEdit = async (payload: TInventoryRelocationDetail) => {
      await inventoryRelocationsDetailQuery.editRecord.mutateAsync(payload)
      await inventoryRelocationsDetailQuery.loadRecords.refetch()
    }

    const onDelete = async (payload: TInventoryRelocationDetail) => {
      await inventoryRelocationsDetailQuery.deleteRecord.mutateAsync(payload)
      await inventoryRelocationsDetailQuery.loadRecords.refetch()
    }

    const onAction = async (payload: TRecordAction) => {
      await moduleQuery.actionRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }
    const details = computed(
      () => inventoryRelocationsDetailQuery.loadRecords.data?.value,
    )
    const isLoading = computed(() => moduleQuery.loadRecord.isLoading.value)
    const isFetching = computed(() => moduleQuery.loadRecord.isFetching.value)
    const stock = computed(
      () => inventoryRelocationsDetailQuery.stockCatalog?.data?.value,
    )

    const isDetailsFetching = computed(
      () =>
        inventoryRelocationsDetailQuery.loadRecords.isLoading.value ||
        inventoryRelocationsDetailQuery.loadRecords.isFetching.value ||
        inventoryRelocationsDetailQuery.addRecord.isLoading.value ||
        inventoryRelocationsDetailQuery.editRecord.isLoading.value ||
        inventoryRelocationsDetailQuery.deleteRecord.isLoading.value,
    )
    const isLoadingStock = computed(
      () => inventoryRelocationsDetailQuery.stockCatalog.isLoading.value,
    )

    return {
      form,
      model,
      details,
      isLoading,
      isFetching,
      isDetailsFetching,
      stockFilters,
      stock,
      isLoadingStock,
      paginationDetails: toReactive(paginationDetails),
      onAction,
      onAdd,
      onEdit,
      onDelete,
    }
  })

export { useInventoryRelocationModule, useInventoryRelocationsDetailModule }
