import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createExportEndpoint,
} from '@/core/utils'
import { THistory } from '.'
import { DataWithPagination, TBaseWarehouse, TKardexCost } from '@/core/types'
import { TArticle } from '../purchase-orders'
import api from '@/core/config/axios'
import { MaybeRef } from 'vue'

export type THistories = DataWithPagination<THistory>

export const serviceConfig = {
  model: 'kardex',
  version: 'v1',
  catalogs: {
    warehouse: {
      model: 'warehouses',
      version: 'v1',
    },
    articles: {
      model: 'articles',
      version: 'v1',
    },
  },
}

const { warehouse, articles } = serviceConfig.catalogs

const moduleServiceHandlers = {
  ...createCrudEndpoints<THistories, THistory>(
    serviceConfig.model,
    serviceConfig.version,
  ),
  loadRecord: async (payload: {
    id?: MaybeRef<number | string>
  }): Promise<TKardexCost> =>
    api
      .get(
        `${serviceConfig.version}/${serviceConfig.model}/${payload.id}/detail`,
      )
      .then((response) => response.data),
}

const warehouseCatalog = createCatalogEndpoint<TBaseWarehouse[]>(
  warehouse.model,
  warehouse.version,
)

const articlesCatalog = createCatalogEndpoint<TArticle[]>(
  articles.model,
  articles.version,
)

const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

const catalogServiceHandlers = {
  warehouseCatalog,
  articlesCatalog,
}

export { moduleServiceHandlers, moduleExportHandlers, catalogServiceHandlers }
