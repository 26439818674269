import { type PageState } from 'primevue/paginator'
import { computed, ref } from 'vue'
import { Pagination } from '../types'

export const usePagination = () => {
  const currentPage = ref(1)

  const pagination = ref<Pagination>({
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 10,
    total: 0,
  })

  const hasPrevPage = computed(() => currentPage.value > 1)

  const hasNextPage = computed(
    () => currentPage.value <= (pagination.value.current_page ?? 1),
  )

  const hasPagination = computed(() => (pagination.value?.last_page ?? 1) > 1)

  const prevPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--
    }
  }

  const nextPage = () => {
    if (currentPage.value < (pagination.value.total ?? 1)) {
      currentPage.value++
    }
  }

  const setPagination = (paginationData: Pagination) => {
    pagination.value = {
      current_page: paginationData.current_page ?? 1,
      from: paginationData.from ?? 1,
      last_page: paginationData.last_page ?? 1,
      per_page: paginationData.per_page ?? 10,
      total: paginationData.total ?? 0,
    }
  }

  const setCurrentPage = (pageState: PageState) => {
    currentPage.value = pageState.page ? pageState.page + 1 : 1
  }

  return {
    currentPage,
    pagination,
    hasPrevPage,
    hasNextPage,
    hasPagination,
    prevPage,
    nextPage,
    setPagination,
    setCurrentPage,
  }
}
