import { type PropType } from 'vue'
import { ModalType } from './composables'

export const authRoles = {
  ADMIN: 'Admin',
  WAREHOUSEMAN: 'Warehouseman',
}

export const roles = [
  {
    label: authRoles.ADMIN,
  },
  {
    label: authRoles.WAREHOUSEMAN,
  },
]

export const warehouses = [
  {
    id: 1,
    name: 'General',
  },
]

export const defaultCharacters = {
  ENTRY: 'Entrada',
  OUTPUT: 'Salida',
}

export const statuses: Record<any, any> = {
  RECEIVED: 'status.RECEIVED',
  ACTIVED: 'status.ACTIVED',
  IN_PROCESS: 'status.IN_PROCESS',
  DRAFTED: 'status.DRAFTED',
  INACTIVED: 'status.INACTIVED',
  CANCELLED: 'status.CANCELLED',
  APPROVED: 'status.APPROVED',
  APPLIED: 'status.APPLIED',
  PENDING: 'status.PENDING',
}

export const defaultStatuses = {
  RECEIVED: 'RECEIVED',
  ACTIVED: 'ACTIVED',
  IN_PROCESS: 'IN_PROCESS',
  DRAFTED: 'DRAFTED',
  INACTIVED: 'INACTIVED',
  CANCELLED: 'CANCELLED',
  APPROVED: 'APPROVED',
  APPLIED: 'APPLIED',
  PENDING: 'PENDING',
}

export const statusesList = Object.keys(defaultStatuses)

export const baseProps = {
  isOpen: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<ModalType>,
    default: 'add',
  },
}

export const months = {
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
}

export const defaultPagination = {
  current_page: 1,
  from: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
}

export const recordActions = {
  actived: 'actived',
  active: 'active',
  inactived: 'inactived',
  inactive: 'inactive',
  cancel: 'cancel',
  approved: 'approved',
  applied: 'applied',
  apply: 'apply',
  added: 'added',
  substracted: 'substracted',
} as const

export const receptionsStatuses = [
  { id: 8, name: 'APPLIED', color: 'bg-green-50 text-green-600' },
  { id: 9, name: 'PENDING', color: 'bg-orange-50 text-orange-600' },
]

export const defaultOrder = {
  icon: 'pi-arrow-up',
  name: 'Fecha de creación',
  value: 'created_at,asc',
}
