import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useGlobalStore = defineStore('global', () => {
  const isSidebarSettingsOpen = ref(false)
  const isMenuNavigationOpen = ref(false)
  const isModalProfileOpen = ref(false)

  const toggleMenuNavigation = () => {
    isMenuNavigationOpen.value = !isMenuNavigationOpen.value
  }

  const toggleModalProfile = (value: boolean) => {
    isModalProfileOpen.value = value
  }

  const closeMenuNavigation = () => {
    isMenuNavigationOpen.value = false
  }

  const toggleSidebarSettings = (value: boolean) => {
    isSidebarSettingsOpen.value = value
  }

  return {
    isSidebarSettingsOpen,
    isMenuNavigationOpen,
    isModalProfileOpen,
    toggleModalProfile,
    toggleSidebarSettings,
    toggleMenuNavigation,
    closeMenuNavigation,
  }
})
