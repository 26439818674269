import type {
  BaseServiceOptions,
  Pagination,
  TBaseWarehouse,
} from '@/core/types'
import {
  moduleServiceHandlers,
  catalogServiceHandlers,
  type TInventories,
  serviceConfig,
} from '../../..'
import { defaultPagination, useNotification } from '@/core'
import { useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { TArticle } from '@/modules/purchase-orders'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()

  const loadRecords = useQuery<TInventories, AxiosError>({
    queryKey: [model, currentPage, filters?.loadRecords],
    queryFn: () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecords,
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      if (data?.meta) {
        handlers?.onDataChange?.(data.meta ?? defaultPagination)
      }
    },
  })

  const warehouseCatalog = useQuery<TBaseWarehouse[]>(
    [serviceConfig.catalogs.warehouse],
    () => catalogServiceHandlers.warehouseCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.warehouseCatalog,
    },
  )

  const articlesCatalog = useQuery<TArticle[], AxiosError>({
    queryKey: [serviceConfig.catalogs.articles, filters?.articlesCatalog],
    queryFn: () =>
      catalogServiceHandlers.articlesCatalog.loadCatalog({
        dynamicFilters: filters?.articlesCatalog,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.articlesCatalog,
  })

  return {
    loadRecords,
    warehouseCatalog,
    articlesCatalog,
  }
}
