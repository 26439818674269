import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { TLocation } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination, TRecordAction } from '@/core/types'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TLocation>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    id: [],
    warehouse_id: [],
    dates: {
      created_at: [],
    },
    order_by: 'status_id,asc',
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id', 'name'],
  })

  const paginationModule = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: paginationModule.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
      warehouseCatalog: true,
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        paginationModule.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const onAdd = async (payload: TLocation) => {
    await moduleQuery.addRecord.mutateAsync(payload)
  }

  const onEdit = async (payload: TLocation) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)
  const warehouses = computed(() => moduleQuery.warehouseCatalog?.data?.value)
  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )
  const isLoadingWarehouses = computed(
    () => moduleQuery.warehouseCatalog.isLoading.value,
  )

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    warehouses,
    isLoading,
    isLoadingWarehouses,
    modulePagination: toReactive(paginationModule),
    onAction,
    onEdit,
    onAdd,
  }
})

export { useProvideModule, useModule }
