<template>
  <div>
    <label :for="id">{{ label }}</label>
    <dot-input-text
      :id="id"
      :placeholder="placeholder"
      :class="[
        'w-full',
        {
          'p-invalid': invalid,
        },
      ]"
      :="$attrs"
    />
    <small v-if="invalid" class="p-error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
    </small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DotInput',
  props: {
    label: {
      type: String,
      default: 'label text',
    },
    placeholder: {
      type: String,
      default: 'placeholder text',
    },
    id: {
      type: String,
      default: 'name',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {}
  },
})
</script>
