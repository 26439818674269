import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createNestedCrudEndpoints,
} from '@/core/utils'
import {
  TPurchaseOrder,
  TPurchaseOrderDetail,
  TReception,
  TReceptionDetail,
} from '.'
import { DataWithPagination } from '@/core/types'
import { TWarehouse } from '../warehouses'

export type TReceptions = DataWithPagination<TReception>
export type TReceptionDetails = DataWithPagination<TReceptionDetail>
export type TPurchaseOrderDetails = DataWithPagination<TPurchaseOrderDetail>

export const serviceConfig = {
  model: 'receipts',
  version: 'v1',
  details: {
    model: 'details',
    version: 'v1',
  },
  purchaseOrderDetails: {
    model: 'details',
    version: 'v1',
  },
  catalogs: {
    purchaseOrders: {
      model: 'purchase-orders',
      version: 'v1',
    },
    warehouses: {
      model: 'warehouses',
      version: 'v1',
    },
  },
}

const { purchaseOrders, warehouses } = serviceConfig.catalogs
const { model: modelDetail, version: versionDetail } = serviceConfig.details
const { model: modelOCDetail, version: versionOCDetail } =
  serviceConfig.purchaseOrderDetails

const moduleServiceHandlers = createCrudEndpoints<TReceptions, TReception>(
  serviceConfig.model,
  serviceConfig.version,
)

const moduleDetailServiceHandlers = createNestedCrudEndpoints<
  TReceptionDetail[],
  TReceptionDetail
>(serviceConfig.model, modelDetail, versionDetail)

const ocDetailsHandlers = createNestedCrudEndpoints<
  TPurchaseOrderDetail[],
  TPurchaseOrderDetail
>(purchaseOrders.model, modelOCDetail, versionOCDetail)

const ocCatalogServiceHandlers = createCatalogEndpoint<TPurchaseOrder[]>(
  purchaseOrders.model,
  purchaseOrders.version,
)

const warehouseCatalogServiceHandlers = createCatalogEndpoint<TWarehouse[]>(
  warehouses.model,
  warehouses.version,
)

const catalogServiceHandlers = {
  ocCatalogServiceHandlers,
  ocDetailsHandlers,
  warehouseCatalogServiceHandlers,
}

export {
  moduleServiceHandlers,
  moduleDetailServiceHandlers,
  catalogServiceHandlers,
}
