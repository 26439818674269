import { createI18n } from 'vue-i18n'
import { es, en } from './lang'

export const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  availableLocales: ['es', 'en'],
  locale: 'es',
  fallbackLocale: 'en',
  messages: { es, en },
})
