import { reactive, onMounted } from 'vue'
import { createInjectionState } from '@vueuse/shared'
import { useLocalStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

type SystemLang = {
  currentLang: string
}

const [useProvideLang, useLang] = createInjectionState(() => {
  const { locale } = useI18n()
  const systemLangStorage = useLocalStorage<string>('lang', 'es')

  const systemLang = reactive<SystemLang>({
    currentLang: locale.value ?? 'es',
  })

  const onChangeLang = (lang: string) => {
    systemLangStorage.value = lang
    locale.value = lang
  }

  onMounted(() => {
    onChangeLang(systemLangStorage.value)
  })

  return {
    systemLang,
    systemLangStorage,
    onChangeLang,
  }
})

export { useProvideLang, useLang }
