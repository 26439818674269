export const es = {
  login: {
    TITLE: 'Iniciar Sesión',
    EMAIL: { TEXT: 'Correo', PLACEHOLDER: 'Correo electrónico' },
    PASSWORD: { TEXT: 'Contraseña', PLACEHOLDER: '**********' },
    RECOVER: { TEXT: 'Olvide mi contraseña' },
  },
  'password-recovery': {
    TITLE: 'Recuperar contraseña',
    SUCCESS: {
      SUMMARY: 'Correo enviado',
      DETAIL: 'Revisa tu correo y sigue las instrucciones',
    },
  },
  roles: {
    ADMIN: 'Administrador',
    WAREHOUSEMAN: 'Almacenista',
  },
  strings: {
    LOGOUT_SUCCESS: 'Cerraste sesión con éxito',
    PROFILE: 'Perfil',
    HOME: 'Inicio',
    DASHBOARD: 'Dashboard',
    USERS: 'Usuarios',
    ARTICLES: 'Materiales',
    CATEGORIES: 'Categorias',
    TYPES: 'Tipos',
    METRICS: 'Unidades de medida',
    RM: 'Recepción de mercancia',
    PURCHASE_ORDERS: 'Órdenes de compra',
    INVENTORY: 'Inventario',
    HISTORY: 'Kardex',
    RECEPTION: 'Recepciones',
    SUPPLIERS: 'Proveedores',
    WELCOME_USER: 'Bienvenido {attribute}',
    ARE_YOU_SURE: {
      TITLE: '{attribute}',
      TEXT: '¿Estás seguro de continuar, esta acción no se podrá revertir?',
    },
    ARE_YOU_SURE_APPLIED: {
      TITLE: '{attribute}',
      TEXT: 'Al aplicar esta salida tendrá un costo unitario de {attribute}',
    },
    MODAL: {
      ADD: 'Agregar {attribute}',
      EDIT: 'Editar {attribute}',
      APPLIED: 'Aplicar {attribute}',
      SHOW: 'Ver {attribute}',
      PROFILE: 'Mi perfil',
    },
    SOMETHING_WENT_WRONG: 'Ups! algo salio mal',
    CRUD_HELPER: '{attribute} con éxito',
    SUBTITLE_SUMMARY: 'Un resumen de sus {attribute}',
    SEARCH: 'Búsqueda',
    ORDER_BY: 'Ordernar',
    NO_DATA_TO_DISPLAY: 'Sin datos para mostrar',
    LOCATIONS: 'Localidades',
    LOCATION_DESTINATION: 'Localidad destino',
    WAREHOUSE_DESTINATION: 'Almacén destino',
    WAREHOUSE: 'Almacén',
    MOVEMENT_REQUEST: 'Movimientos manuales',
    OUT: 'Salida',
    STOCK: 'Existencia',
    APPROXIMATE: 'Aproximado',
  },
  filters: {
    TITLE: 'Filtros',
  },
  actions: {
    LOGOUT: 'Cerrar sesión',
    CANCEL: 'Cancelar',
    CLOSE: 'Cerrar',
    ADD: 'Agregar',
    ACCEPT: 'Aceptar',
    SAVE: 'Guardar',
    EDIT: 'Editar',
    DELETE: 'Eliminar',
    EXPORT: 'Exportar',
    IMPORT: 'Importar',
    CHOOSE: 'Seleccionar',
    ACTIVATE: 'Activar',
    DETAIL: 'Detalle',
    ADDED: 'Guardado',
    UPDATED: 'Actualizado',
    DOWNLOADED: 'Descargado | Descargar',
    DELETED: 'Eliminado',
    SEE: 'Ver',
    NEW: 'Nuevo {attribute}',
    APPROVED: 'Aprobar',
    PRINT: 'Imprimir',
    ADD_ARTICLE: 'Agregar artículo',
    RECOVER: 'Recuperar',
    APPLIED: 'Aplicar',
    APPLY: 'Aplicar',
    ENTRY: 'Entrada',
    OUT: 'Salida',
    INACTIVE: 'Inactivar',
  },
  settings: {
    TITLE: 'Configuración',
    THEME: 'Tema',
    THEME_MODE: { LIGHT: 'Claro', DARK: 'Obscuro' },
    LANG: 'Idioma',
  },
  locale: {
    ES: 'Español',
    EN: 'Ingles',
  },
  users: {
    MODEL: 'Usuario | Usuarios',
    PASSWORD: 'Contraseña',
    PASSWORD_CONFIRM: 'Confirmar contraseña',
  },
  suppliers: {
    MODEL: 'Proveedor | Proveedores',
  },
  articles: {
    MODEL: 'Material | Materiales',
  },
  inventories: {
    MODEL: 'Consulta de exitencia | Consulta de exitencias',
  },
  history: {
    MODEL: 'Consulta de Kardex | Consulta de los Kardex',
    INITIAL_AMOUNT: 'Costo inicial',
    AMOUNT: 'Costo entrada',
    FINAL_AMOUNT: 'Costo salida',
    DEPARTURE_AMOUNT: 'Costo final',
  },
  categories: {
    MODEL: 'Categoría | Categorías',
  },
  locations: {
    MODEL: 'Localidad | Localidades',
  },
  types: {
    MODEL: 'Tipo | Tipos',
  },
  details: {
    MODEL: 'Detalle | Detalles',
  },
  'measurement-units': {
    MODEL: 'Unidad de medida | Unidades de medida',
  },
  warehouses: {
    MODEL: 'Almacén | Almacenes',
  },
  'purchase-orders': {
    MODEL: 'Orden de compra | Órdenes de compra',
  },
  'inventory-relocations': {
    MODEL: 'Reubicación de material | Reubicación de materiales',
  },
  'inventory-transfers': {
    MODEL: 'Traspaso de material | Traspasos de materiales',
  },
  'movement-requests': {
    MODEL: 'Movimiento manual | Movimientos manuales',
  },
  'purchase-order-details': {
    MODEL: 'Detalle Orden de compra | Detalles Órdenes de compra',
  },
  certificates: {
    MODEL: 'Certificado | Certificados',
    EXISTING: 'Certificados existentes',
  },
  receipts: {
    MODEL: 'Consulta de recepción | Consulta de recepciones | Recepciones',
    MODEL_SECONDARY: 'Recepción de mercancia | Recepción de mercancias',
    CREATING: 'Estamos creando la recepción, espera un momento....',
  },
  columns: {
    INDICATOR: 'Indicador',
    USER: 'Usuario',
    PRODUCT: 'Producto',
    NAME: 'Nombre',
    PRICE: 'Precio',
    STATUS: 'Estatus',
    CREATED_AT: 'Fecha de creación',
    SUPPLIER: 'Proveedor',
    DATE: 'Fecha',
    INIT_DATE: 'Fecha de colocación',
    ENTRY_DATE: 'Fecha de llegada',
    TOTAL: 'Total',
    CURRENCY: 'Moneda',
    ACTIONS: 'Acciones',
    FOLIO: 'Folio',
    LAST_NAME: 'Apellido',
    EMAIL: 'Correo',
    ROL: 'Rol',
    TITLE: 'Título',
    DESCRIPTION: 'Descripción',
    PART_NUMBER: 'No.Parte',
    CATEGORY: 'Categoria',
    TYPE: 'Tipo de movimiento',
    METRIC: 'U.M',
    ID: 'ID',
    ARTICLE_NAME: 'Nombre artículo',
    ARTICLE: 'Artículo',
    QUANTITY: 'Cantidad',
    UNIT_PRICE: 'Precio Unitario',
    TAX: 'Impuesto',
    WAREHOUSE: 'Almacén',
    REASON: 'Motivo',
    PURCHASE_ORDER: 'Orden de compra',
    RM: 'No.RM',
    TYPE_MOVEMENT: 'T.M',
    CERTIFICATE: 'Certificado | Nota de certificado',
    INITIAL_QUANTITY: 'C.I',
    AMOUNT: 'C',
    FINAL_QUANTITY: 'C.F',
    FOLIO_SUPPLIER_INVOICE: 'Folio de factura de proveedor',
    NO: 'No.',
    PEDIMENT: 'Pedimento',
    REMAINING: 'Restante',
    TO_BE_RECEIVED: 'Por recibir',
    RECEIVED: 'Recibido',
    TOTAL_RECEIVED: 'Total Recibido',
    RFC: 'RFC',
    CONDITIONS: 'Condiciones',
    INITIAL_QUANTITY_TEXT: 'Cantidad inicial',
    AMOUNT_TEXT: 'Cantidad',
    FINAL_QUANTITY_TEXT: 'Cantidad final',
    CERTIFICATE_FOLIO: 'Folio de Certificado',
    UNIT_COST: 'Costo Unitario',
    RELOCATION: 'Reubicaión',
    TRANSFERS: 'Traspasos',
  },
  status: {
    ACTIVED: 'Activo',
    DELETED: 'Eliminado',
    INACTIVED: 'Inactivo',
    REQUESTED: 'En proceso',
    PENDING: 'Pendiente',
    APPLIED: 'Aplicado',
    DONE: 'Completado',
    DRAFTED: 'Borrador',
    CANCELLED: 'Cancelado',
    IN_PROCESS: 'Proceso',
    RECEIVED: 'Recibida',
    APPROVED: 'Aprobado',
  },
  validation: {
    ACCEPTED: 'El campo {attribute} debe ser aceptado.',
    ACTIVE_URL: 'El campo {attribute} no es una URL válida.',
    AFTER: 'El campo {attribute} debe ser una fecha después a {date}.',
    AFTER_OR_EQUAL:
      'El campo {attribute} debe ser una fecha posterior o igual a {date}.',
    ALPHA: 'El campo {attribute} solo puede contener letras.',
    ALPHA_DASH:
      'El campo {attribute} solo puede contener letras, números, guiones y guiones bajos.',
    ALPHA_NUM: 'El campo {attribute} solo puede contener letras y números.',
    ARRAY: 'El campo {attribute} debe ser una matriz.',
    BEFORE: 'El campo {attribute} debe ser una fecha antes de {date}.',
    BEFORE_OR_EQUAL:
      'El campo {attribute} debe ser una fecha antes o igual a {date}.',
    BETWEEN: {
      NUMERIC: 'El {attribute} debe estar entre {min} y {max}.',
      FILE: 'El {attribute} debe estar entre {min} y {max} kilobytes.',
      STRING: 'El {attribute} debe estar entre {min} y {max} caracteres.',
      ARRAY: 'El {attribute} debe estar entre {min} y {max} artículos.',
    },
    BOOLEAN: 'El campo {attribute} debe ser verdadero o falso.',
    CONFIRMED: 'El {attribute} la confirmación no coincide.',
    DATE: 'La {attribute} no es una fecha valida.',
    DATE_FORMAT: 'La {attribute} no coincide con el formato {format}.',
    DIFFERENT: 'El {attribute} y {other} deben ser diferentes.',
    DIGITS: 'El {attribute} deben ser {digits} dígitos.',
    DIGITS_BETWEEN: 'El {attribute} debe estar entre {min} y {max} dígitos.',
    DIMENSIONS: 'El {attribute} tiene dimensiones de imagen inválidas.',
    DISTINCT: 'El {attribute} campo tiene un valor duplicado.',
    EMAIL:
      'El campo {attribute} debe ser una dirección de correo electrónico válida.',
    EXISTS: 'El seleccionado {attribute} no es válido.',
    FILE: 'El {attribute} debe ser un archivo.',
    FILLED: 'El {attribute} campo debe tener un valor.',
    GT: {
      NUMERIC: 'El {attribute} debe ser mayor que {value}.',
      FILE: 'El {attribute} debe ser mayor que {value} kilobytes.',
      STRING: 'El {attribute} debe ser mayor que {value} caracteres.',
      ARRAY: 'El {attribute} debe tener más que {value} artículos.',
    },
    GTE: {
      NUMERIC: 'El {attribute} debe ser mayor o igual que {value}.',
      FILE: 'El {attribute} debe ser mayor o igual que {value} kilobytes.',
      STRING: 'El {attribute} debe ser mayor o igual que {value} caracteres.',
      ARRAY: 'El {attribute} debe tener {value} artículos o más.',
    },
    IMAGE: 'La {attribute} debe ser una imagen.',
    IN: 'El seleccionado {attribute} no es válido.',
    IN_ARRAY: 'El {attribute} campo no existe en {other}.',
    INTEGER: 'El {attribute} debe ser un entero.',
    IP: 'La {attribute} debe ser una dirección IP válida.',
    IPV4: 'La {attribute} debe ser una dirección IPv4 válida.',
    IPV6: 'La {attribute} debe ser una dirección IPv6 válida.',
    JSON: 'La {attribute} debe ser una cadena JSON válida.',
    LT: {
      NUMERIC: 'El {attribute} debe ser menor que {value}.',
      FILE: 'El {attribute} debe ser menor que {value} kilobytes.',
      STRING: 'El {attribute} debe ser menor que {value} caracteres.',
      ARRAY: 'El {attribute} debe tener menos que {value} artículos.',
    },
    LTE: {
      NUMERIC: 'El {attribute} debe ser menor o igual {value}.',
      FILE: 'El {attribute} debe ser menor o igual {value} kilobytes.',
      STRING: 'El {attribute} debe ser menor o igual {value} caracteres.',
      ARRAY: 'El {attribute} no debe tener más de {value} artículos.',
    },
    MAX: {
      NUMERIC: 'El {attribute} no puede ser mayor que {max}.',
      FILE: 'El {attribute} no puede ser mayor que {max} kilobytes.',
      STRING: 'El {attribute} no puede ser mayor que {max} caracteres.',
      ARRAY: 'El {attribute} no puede tener más de {max} artículos.',
    },
    MIMES: 'El {attribute} must be a file of type: {values}.',
    MIMETYPES: 'Los {attribute} must be a file of type: {values}.',
    MIN: {
      NUMERIC: 'El campo {attribute} debe ser al menos de {min}.',
      FILE: 'El campo {attribute} debe ser al menos de {min} kilobytes.',
      STRING: 'El campo {attribute} debe ser al menos de {min} caracteres.',
      ARRAY: 'El campo {attribute} debe tener al menos {min} artículos.',
    },
    NOT_IN: 'El seleccionado {attribute} no es válido.',
    NOT_REGEX: 'El {attribute} formato no es válido.',
    NUMERIC: 'El campo {attribute} tiene que ser un número.',
    PRESENT: 'El campo {attribute} debe estar presente.',
    REGEX: 'El campo {attribute} formato no es válido.',
    REQUIRED: 'El campo {attribute} es requerido.',
    REQUIRED_IF: 'El campo {attribute} es requerido cuando {other} es {value}.',
    REQUIRED_UNLESS:
      'El campo {attribute} es requerido a menos que {other} es en {values}.',
    REQUIRED_WITH:
      'El campo {attribute} es requerido cuando {values} esta presente.',
    REQUIRED_WITH_ALL:
      'El campo {attribute} es requerido cuando {values} esta presente.',
    REQUIRED_WITHOUT:
      'El campo {attribute} es requerido cuando {values} no esta presente.',
    REQUIRED_WITHOUT_ALL:
      'El campo {attribute} es requerido cuando ninguno de {values} estan presentes.',
    SAME: 'El campo {attribute} debe coincidir con {other}.',
    SIZE: {
      NUMERIC: 'El {attribute} debe ser {size}.',
      FILE: 'El {attribute} debe ser {size} kilobytes.',
      STRING: 'El {attribute} debe ser {size} caracteres.',
      ARRAY: 'El {attribute} debe contener {size} artículos.',
    },
    STRING: 'El {attribute} debe ser un texto.',
    TIMEZONE: 'El {attribute} debe ser una zona válida.',
    UNIQUE: 'El {attribute} ya se ha tomado.',
    UPLOADED: 'El {attribute} no se pudo cargar.',
    URL: 'El {attribute} formato no válido.',
    PASSWORD_EQUALS: 'Las contraseñas no coinciden.',
    ATTRIBUTES: {},
  },
}
