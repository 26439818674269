import { createCrudEndpoints, createExportEndpoint } from '@/core/utils'
import { TUser } from '.'
import { DataWithPagination } from '@/core/types'

export type TUsers = DataWithPagination<TUser>

export const serviceConfig = {
  model: 'users',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<TUsers, TUser>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandler = createExportEndpoint(serviceConfig.model)

export { moduleServiceHandlers, moduleExportHandler }
