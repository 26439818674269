import { createCrudEndpoints, createExportEndpoint } from '@/core/utils'
import { TCategory } from '.'
import { DataWithPagination } from '@/core/types'

export type TCategories = DataWithPagination<TCategory>

export const serviceConfig = {
  model: 'categories',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<TCategories, TCategory>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

export { moduleServiceHandlers, moduleExportHandlers }
