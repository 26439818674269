import { defineStore } from 'pinia'
import { ref } from 'vue'
import { TReceptionDetail } from '../../types'
import { TBasePurchaseOrderDetail } from '@/core/types'

export const useReceptionDetailsStore = defineStore('receptionDetails', () => {
  const details = ref<
    Array<TReceptionDetail | Partial<TBasePurchaseOrderDetail>>
  >([])

  const setDetails = (
    payload: Array<TReceptionDetail | Partial<TBasePurchaseOrderDetail>>,
  ) => {
    details.value = payload
  }

  return {
    details,
    setDetails,
  }
})
