import api from '@/core/config/axios'
import { TFilters } from '../types'
import { AxiosResponse } from 'axios'
import { toValue } from 'vue'

export const createExportEndpoint = (model: string) => {
  const exportRecords = async (params: TFilters, format: string = 'csv') => {
    const urlSearchParams = [
      `page=${toValue(params.page ?? 1)}`,
      toValue(params.dynamicFilters ?? ''),
    ]
      .filter(Boolean)
      .join('&')

    try {
      const response = await api.get(`/${model}/export?${urlSearchParams}`, {
        responseType: 'blob',
        params: {
          format,
        },
      })

      download(response, format)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const download = (response: AxiosResponse, format: string) => {
    const filename = `${model}.${format}`

    const blob = new Blob([response.data], { type: 'application/octet-stream' })

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  return {
    exportRecords,
  }
}
