import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createExportEndpoint,
} from '@/core/utils'
import { TInventory, TInventoryHistory } from '.'
import { DataWithPagination, TBaseWarehouse } from '@/core/types'
import { TArticle } from '../purchase-orders'

export type TInventories = DataWithPagination<TInventory>
export type TInventoryMovements = DataWithPagination<TInventoryHistory>

export const serviceConfig = {
  model: 'stock',
  version: 'v1',
  details: {
    model: 'inventory-movement',
    version: 'v1',
  },
  catalogs: {
    warehouse: {
      model: 'warehouses',
      version: 'v1',
    },
    articles: {
      model: 'articles',
      version: 'v1',
    },
  },
}

const { model: modelDetails, version: versionDetails } = serviceConfig.details
const { warehouse, articles } = serviceConfig.catalogs

const moduleServiceHandlers = createCrudEndpoints<TInventories, TInventory>(
  serviceConfig.model,
  serviceConfig.version,
)

const moduleDetailServiceHandlers = createCrudEndpoints<
  TInventoryMovements,
  TInventoryHistory
>(modelDetails, versionDetails)

const warehouseCatalog = createCatalogEndpoint<TBaseWarehouse[]>(
  warehouse.model,
  warehouse.version,
)

const articlesCatalog = createCatalogEndpoint<TArticle[]>(
  articles.model,
  articles.version,
)

const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

const catalogServiceHandlers = {
  warehouseCatalog,
  articlesCatalog,
}

export {
  moduleServiceHandlers,
  moduleExportHandlers,
  moduleDetailServiceHandlers,
  catalogServiceHandlers,
}
