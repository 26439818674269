import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN } = authRoles

const History = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

export const historysModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/history',
    name: 'history',
    component: History,
    meta: {
      title: 'Kardex',
      auth: {
        roles: [ADMIN],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
