import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export type OrderByParams = {
  columns: string[]
}

export type OrderByItem = {
  name?: string
  value: string
  icon?: string
}

export const useOrderByFilter = (params?: OrderByParams) => {
  const orderByOptions = ref<OrderByItem[]>([])

  const { t } = useI18n()

  const onBuildOptions = () => {
    params?.columns.forEach((column) => {
      orderByOptions.value.push({
        name: `${t(`columns.${column.toUpperCase()}`)},asc`,
        value: `${column},asc`,
      })
      orderByOptions.value.push({
        name: `${t(`columns.${column.toUpperCase()}`)},desc`,
        value: `${column},desc`,
      })
    })
  }

  onMounted(() => {
    onBuildOptions()
  })

  return { orderByOptions }
}
