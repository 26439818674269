import type {
  BaseServiceOptions,
  Pagination,
  TImportFile,
  TRecordAction,
} from '@/core/types'
import {
  type TPurchaseOrder,
  moduleServiceHandlers,
  modulePrintHandlers,
  moduleExportHandlers,
  serviceConfig,
  type TPurchaseOrders,
  TCurrency,
  catalogServiceHandlers,
  TSupplier,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, modelId, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TPurchaseOrder>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TPurchaseOrder, AxiosError, TPurchaseOrder>(
    (payload: TPurchaseOrder) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TPurchaseOrder, AxiosError, TPurchaseOrder>(
    (payload: TPurchaseOrder) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TPurchaseOrder, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const importRecords = useMutation<void, AxiosError, TImportFile>(
    (payload: TImportFile) => {
      const formData = new FormData()
      formData.append('importFile', payload.file)
      return moduleServiceHandlers.importRecords(formData)
    },
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TPurchaseOrders>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onDataChange?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const loadRecord = useQuery<TPurchaseOrder, AxiosError>({
    queryKey: [model, modelId],
    queryFn: ({ queryKey }) =>
      moduleServiceHandlers.loadRecord({
        id: Number(queryKey[1]),
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecord,
    onError: (error) => errorHttpNotification(error),
    select: (data) => ({
      ...data,
      tax_rate: Number(),
    }),
  })

  const currenciesCatalog = useQuery<TCurrency[]>(
    [serviceConfig.catalogs.currencies],
    () => catalogServiceHandlers.currencyCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.currenciesCatalog,
    },
  )

  const suppliersCatalog = useQuery<TSupplier[]>(
    [serviceConfig.catalogs.suppliers, filters?.suppliers],
    () =>
      catalogServiceHandlers.suppliersCatalog.loadCatalog({
        dynamicFilters: filters?.suppliers,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.suppliersCatalog,
    },
  )

  const printRecord = async (id: number) =>
    await modulePrintHandlers.printRecord(id)

  const exportRecords = async () =>
    await moduleExportHandlers.exportRecords({
      dynamicFilters: filters?.loadRecords,
    })

  return {
    suppliersCatalog,
    currenciesCatalog,
    loadRecord,
    loadRecords,
    addRecord,
    editRecord,
    importRecords,
    actionRecord,
    exportRecords,
    printRecord,
  }
}
