import V404 from '@/core/components/errors/404.vue'
import { RouteRecordRaw } from 'vue-router'

export const errorRoutes: Array<RouteRecordRaw> = [
  {
    path: '/404',
    name: '404',
    component: V404,
  },
]
