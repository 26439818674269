import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import { useModuleQuery } from '../query/useModuleQuery'
import { useRoute } from 'vue-router'
import { usePurchaseDetailsQuery } from '../query/usePurchaseDetailsQuery'
import { ToQueryStringFilters, usePagination, useToQueryString } from '@/core'
import { TPurchaseOrderDetail } from '@/modules/purchase-orders/types'
const [useProvidePurchaseModule, usePurchaseModule] = createInjectionState(
  () => {
    const route = useRoute()

    const form = ref<TPurchaseOrderDetail>({})
    const materialsFilters = ref<ToQueryStringFilters>({
      param: '',
      status_id: [1],
      order_by: 'created_at,desc',
    })

    const materialsQueryParams = useToQueryString(
      toReactive(materialsFilters),
      {
        searchColumns: ['id', 'part_number', 'name'],
      },
    )
    const paginationDetails = usePagination()

    const moduleQuery = useModuleQuery({
      modelId: computed(() => route.params?.id.toString()),
      enableQuery: {
        loadRecords: false,
        currenciesCatalog: false,
        suppliersCatalog: false,
        loadRecord: computed(() => !!route.params?.id),
      },
    })

    const purchaseDetailsQuery = usePurchaseDetailsQuery({
      modelId: computed(() => route.params?.id.toString()),
      currentPage: paginationDetails.currentPage,
      enableQuery: {
        loadRecords: computed(() => Boolean(route.params?.id)),
      },
      filters: {
        articlesCatalog: materialsQueryParams.queryParams,
      },
      handlers: {
        onDataChange: (pagination) => {
          paginationDetails.setPagination(pagination)
        },
      },
    })

    const onAdd = async (payload: TPurchaseOrderDetail) => {
      await purchaseDetailsQuery.addRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const onEdit = async (payload: TPurchaseOrderDetail) => {
      await purchaseDetailsQuery.editRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const onDelete = async (payload: TPurchaseOrderDetail) => {
      await purchaseDetailsQuery.deleteRecord.mutateAsync(payload)
      await moduleQuery.loadRecord.refetch()
    }

    const model = computed(() => ({
      ...moduleQuery.loadRecord?.data?.value,
      tax_rate: moduleQuery.loadRecord?.data?.value?.tax_rate?.toFixed(2),
    }))
    const details = computed(() => purchaseDetailsQuery.loadRecords.data?.value)
    const articles = computed(
      () => purchaseDetailsQuery.articlesCatalog.data.value,
    )
    const isArticlesLoading = computed(
      () => purchaseDetailsQuery.articlesCatalog.isLoading.value,
    )
    const isLoading = computed(() => moduleQuery.loadRecord.isLoading.value)
    const isFetching = computed(() => moduleQuery.loadRecord.isFetching.value)

    const isDetailsFetching = computed(
      () =>
        purchaseDetailsQuery.loadRecords.isLoading.value ||
        purchaseDetailsQuery.loadRecords.isFetching.value ||
        purchaseDetailsQuery.addRecord.isLoading.value ||
        purchaseDetailsQuery.editRecord.isLoading.value ||
        purchaseDetailsQuery.deleteRecord.isLoading.value,
    )

    return {
      form,
      articles,
      model,
      details,
      isLoading,
      isFetching,
      isDetailsFetching,
      materialsFilters,
      isArticlesLoading,
      paginationDetails: toReactive(paginationDetails),
      onAdd,
      onEdit,
      onDelete,
    }
  },
)

export { useProvidePurchaseModule, usePurchaseModule }
