import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useDate,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { TPurchaseOrder } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination, TImportFile, TRecordAction } from '@/core/types'
import { useRouter } from 'vue-router'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TPurchaseOrder>({})

  const supplierFilters = ref<ToQueryStringFilters>({
    status_id: [1],
    order_by: 'created_at,desc',
  })

  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const router = useRouter()

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id', 'supplier.name'],
  })
  const supplierQueryParams = useToQueryString(toReactive(supplierFilters))

  const { zonedTimeToUtc } = useDate()

  const paginationModule = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: paginationModule.currentPage,
    filters: {
      suppliers: supplierQueryParams.queryParams,
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecord: false,
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        paginationModule.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'id'],
  })

  const onAdd = async (payload: TPurchaseOrder) => {
    const order = await moduleQuery.addRecord.mutateAsync({
      ...payload,
      entry_date: zonedTimeToUtc(new Date(payload.entry_date ?? ''), {
        removeTimeZone: true,
      }),
      init_date: zonedTimeToUtc(new Date(payload.init_date ?? ''), {
        removeTimeZone: true,
      }),
    })
    onRedirectToEdit(order)
  }

  const onEdit = async (payload: TPurchaseOrder) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onPrint = async (id: number) => {
    await moduleQuery.printRecord(id)
  }

  const onExport = async () => {
    await moduleQuery.exportRecords()
  }

  const onImport = async (data: TImportFile) => {
    await moduleQuery.importRecords.mutateAsync(data)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onRedirectToEdit = (order: TPurchaseOrder) => {
    router.push({
      name: 'purchaseOrdersDetail',
      params: {
        id: order.id,
      },
    })
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)
  const model = computed(() => moduleQuery.loadRecord?.data?.value)
  const currencies = computed(() => moduleQuery.currenciesCatalog?.data?.value)
  const suppliers = computed(() => moduleQuery.suppliersCatalog?.data?.value)
  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )

  return {
    model,
    currencies,
    suppliers,
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    paginationModule: toReactive(paginationModule),
    isLoading,
    onExport,
    onImport,
    onAction,
    onPrint,
    onEdit,
    onAdd,
  }
})

export { useProvideModule, useModule }
