import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import {
  type TInventoryRelocation,
  moduleServiceHandlers,
  serviceConfig,
  type TInventoryRelocations,
  catalogServiceHandlers,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { TWarehouse } from '@/modules/warehouses'
import { TLocations } from '@/modules/locations'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, modelId, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TInventoryRelocation>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<
    TInventoryRelocation,
    AxiosError,
    TInventoryRelocation
  >(
    (payload: TInventoryRelocation) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<
    TInventoryRelocation,
    AxiosError,
    TInventoryRelocation
  >(
    (payload: TInventoryRelocation) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<
    TInventoryRelocation,
    AxiosError,
    TRecordAction
  >((payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload), {
    onSuccess: (payload) => serviceHandlers.updateRecord(payload),
    onError: (error) => errorHttpNotification(error),
  })

  const loadRecords = useQuery<TInventoryRelocations>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onDataChange?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const loadRecord = useQuery<TInventoryRelocation, AxiosError>({
    queryKey: [model, modelId],
    queryFn: ({ queryKey }) =>
      moduleServiceHandlers.loadRecord({
        id: Number(queryKey[1]),
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecord,
    onError: (error) => errorHttpNotification(error),
    select: (data) => ({
      ...data,
    }),
  })

  const warehouseCatalog = useQuery<TWarehouse[]>(
    [serviceConfig.catalogs.warehouses],
    () => catalogServiceHandlers.warehouseCatalog.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.warehouseCatalog,
    },
  )

  const locationsCatalog = useQuery<TLocations[]>(
    [serviceConfig.catalogs.locations],
    () =>
      catalogServiceHandlers.locationsCatalog.loadCatalog({
        dynamicFilters: filters?.locationsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.locationsCatalog,
    },
  )

  return {
    warehouseCatalog,
    locationsCatalog,
    loadRecord,
    loadRecords,
    addRecord,
    editRecord,
    actionRecord,
  }
}
