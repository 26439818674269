import { createCrudEndpoints } from '@/core/utils'
import { TMetric } from '.'
import { DataWithPagination } from '@/core/types'

export type TMetrics = DataWithPagination<TMetric>

export const serviceConfig = {
  model: 'measurement-units',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<TMetrics, TMetric>(
  serviceConfig.model,
  serviceConfig.version,
)

export { moduleServiceHandlers }
