import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createExportEndpoint,
  createNestedCrudEndpoints,
  createPrintEndpoint,
} from '@/core/utils'
import {
  TArticle,
  TCurrency,
  TPurchaseOrder,
  TPurchaseOrderDetail,
  TSupplier,
} from '.'
import { DataWithPagination } from '@/core/types'

export type TPurchaseOrders = DataWithPagination<TPurchaseOrder>

export const serviceConfig = {
  model: 'purchase-orders',
  version: 'v1',
  details: {
    model: 'details',
    version: 'v1',
  },
  catalogs: {
    currencies: {
      model: 'currencies',
      version: 'v1',
    },
    suppliers: {
      model: 'suppliers',
      version: 'v1',
    },
    articles: {
      model: 'articles',
      version: 'v1',
    },
  },
}

const { currencies, suppliers, articles } = serviceConfig.catalogs
const { model: modelDetails, version: versionDetails } = serviceConfig.details

const moduleServiceHandlers = createCrudEndpoints<
  TPurchaseOrders,
  TPurchaseOrder
>(serviceConfig.model, serviceConfig.version)

const moduleDetailServiceHandlers = createNestedCrudEndpoints<
  TPurchaseOrderDetail[],
  TPurchaseOrderDetail
>(serviceConfig.model, modelDetails, versionDetails)

const modulePrintHandlers = createPrintEndpoint(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

const currencyCatalog = createCatalogEndpoint<TCurrency[]>(
  currencies.model,
  currencies.version,
)
const suppliersCatalog = createCatalogEndpoint<TSupplier[]>(
  suppliers.model,
  suppliers.version,
)
const articlesCatalog = createCatalogEndpoint<TArticle[]>(
  articles.model,
  articles.version,
)

const catalogServiceHandlers = {
  currencyCatalog,
  suppliersCatalog,
  articlesCatalog,
}

export {
  moduleServiceHandlers,
  modulePrintHandlers,
  moduleExportHandlers,
  catalogServiceHandlers,
  moduleDetailServiceHandlers,
}
