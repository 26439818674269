import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN } = authRoles

const Articles = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

export const articlesModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/articles',
    name: 'articles',
    component: Articles,
    meta: {
      title: 'Materiales',
      auth: {
        roles: [ADMIN],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
