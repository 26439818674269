import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { TUser, TUserForm } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination, TImportFile, TRecordAction } from '@/core/types'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TUser & TUserForm>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['name', 'email'],
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'email'],
  })

  const onAdd = async (payload: TUser & TUserForm) => {
    await moduleQuery.addRecord.mutateAsync(payload)
  }

  const onEdit = async (payload: TUser & TUserForm) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onDeleteMany = async () => {
    await moduleQuery.deleteRecords.mutateAsync(rows.value)
  }

  const onDelete = async (payload: TUser) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const onExport = async () => {
    await moduleQuery.exportRecords()
  }

  const onImport = async (data: TImportFile) => {
    await moduleQuery.importRecords.mutateAsync(data)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    onImport,
    onAction,
    onExport,
    onDelete,
    onDeleteMany,
    onEdit,
    onAdd,
  }
})

export { useProvideModule, useModule }
