import { reactive, ref, toRefs } from 'vue'
import type {
  Instance as PopperInstance,
  Options as PopperOptions,
} from '@popperjs/core'
import { createPopper as defaultCreatePopper } from '@popperjs/core'

type Options = Partial<
  {
    createPopper: typeof defaultCreatePopper
  } & PopperOptions
>

type State = {
  styles: {
    ['popper']: Record<string, unknown>
  }
  attributes: {
    [key: string]: { [key: string]: string }
  }
}

export function usePopper(options: Options = {}) {
  const optionsWithDefaults = {
    onFirstUpdate: options.onFirstUpdate,
    placement: options.placement || 'bottom',
    strategy: options.strategy || 'absolute',
    modifiers: options.modifiers || [],
  }

  const state = reactive<State>({
    styles: {
      popper: {
        position: optionsWithDefaults.strategy,
        left: '0',
        top: '0',
      },
    },
    attributes: {},
  })

  const popperInstanceRef = ref<PopperInstance | null>(null)

  return {
    ...toRefs(state),
    state: popperInstanceRef,

    createInstance(
      referenceElement: HTMLElement | undefined,
      popperElement: HTMLElement | undefined,
    ) {
      if (!referenceElement || !popperElement) {
        return
      }
      const createPopper = options.createPopper || defaultCreatePopper
      const popperInstance = createPopper(referenceElement, popperElement, {
        onFirstUpdate: optionsWithDefaults.onFirstUpdate,
        placement: optionsWithDefaults.placement,
        strategy: optionsWithDefaults.strategy,
        modifiers: optionsWithDefaults.modifiers,
      })
      popperInstanceRef.value = popperInstance
    },

    update() {
      popperInstanceRef.value?.update()
    },

    forceUpdate() {
      popperInstanceRef.value?.forceUpdate()
    },

    removeInstance() {
      if (popperInstanceRef.value) {
        popperInstanceRef.value.destroy()
        popperInstanceRef.value = null
      }
    },
  } as const
}
