import type { BaseServiceOptions, Pagination } from '@/core/types'
import {
  moduleDetailServiceHandlers,
  serviceConfig,
  TReceptionDetail,
  catalogServiceHandlers,
} from '../../..'
import { useNotification } from '@/core'
import { useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { useReceptionDetailsStore } from '../../store/details'
import { TPurchaseOrder } from '@/modules/purchase-orders'
import { TWarehouse } from '@/modules/warehouses'

const { model } = serviceConfig.details
const { purchaseOrders } = serviceConfig.catalogs
const { ocCatalogServiceHandlers, warehouseCatalogServiceHandlers } =
  catalogServiceHandlers
export const useReceptionDetailsQuery = (
  options: BaseServiceOptions<Pagination>,
) => {
  const { modelId, filters, enableQuery } = options

  const store = useReceptionDetailsStore()
  const { errorHttpNotification } = useNotification()

  const loadRecords = useQuery<TReceptionDetail[], AxiosError>({
    queryKey: [model, modelId],
    select: (data) =>
      data.map((record) => {
        const ocDetailBalance = Number(
          record?.purchase_order_detail_id?.quantity_balance ?? 0,
        )
        const receptionDetailQuantity = Number(record?.quantity ?? 0)
        const receptionDetailBalance = Number(record?.quantity_balance ?? 0)
        const toReceived = Number(
          receptionDetailQuantity - ocDetailBalance,
        ).toFixed(2)
        return {
          ...record,
          quantity: receptionDetailQuantity.toFixed(2),
          to_receive: toReceived,
          quantity_balance: receptionDetailBalance.toFixed(2),
          quantity_received: ocDetailBalance.toFixed(2),
        }
      }),
    queryFn: () =>
      moduleDetailServiceHandlers.loadRecords({
        page: 1,
        modelId,
      }),
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      store.setDetails(data)
    },
    enabled: enableQuery?.loadRecords,
    refetchOnWindowFocus: false,
  })

  const purchaseOrdersCatalog = useQuery<TPurchaseOrder[], AxiosError>({
    queryKey: [purchaseOrders, filters?.purchaseOrdersCatalog],
    queryFn: () =>
      ocCatalogServiceHandlers.loadCatalog({
        dynamicFilters: filters?.purchaseOrdersCatalog,
      }),
    onError: (error) => errorHttpNotification(error),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.purchaseOrdersCatalog,
  })

  const warehouseCatalog = useQuery<TWarehouse[], AxiosError>(
    [serviceConfig.catalogs.warehouses],
    () => warehouseCatalogServiceHandlers.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.warehouseCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    loadRecords,
    purchaseOrdersCatalog,
    warehouseCatalog,
  }
}
