import { useI18n } from 'vue-i18n'
import { months } from '../constants'
import { ref } from 'vue'
import { useDateFormat } from '@vueuse/core'
import { format } from 'date-fns-tz'

type ManipulateDateParams = {
  days?: number
  months?: number
  hours?: number
  minutes?: number
  years?: number
}

type TZoneTimeOptions = {
  removeTimeZone?: boolean
}

type ManipulateDate = {
  date: string | Date
  operation: 'add' | 'subtract'
  params: ManipulateDateParams
}
export const useDate = () => {
  const { value: formattedDate } = useDateFormat(new Date(), 'YYYY-M-D')
  const formattedDateParts = formattedDate.split('-')
  const currentYear = Number(formattedDateParts[0])
  const currentMonth = Number(formattedDateParts[1])
  const currentDay = new Date().getDate()
  const monthRange = ref([
    `${currentYear}-${
      currentMonth < 10 ? '0' + currentMonth : currentMonth
    }-01T06:00`,
    `${currentMonth == 12 ? currentYear + 1 : currentYear}-${
      currentMonth == 12
        ? '0' + 1
        : currentMonth + 1 < 10
        ? '0' + currentMonth
        : currentMonth
    }-01T06:00`,
  ])

  const { locale } = useI18n()

  const getCurrentDate = () => {
    const lang = locale.value as 'es' | 'en'
    const currentDate = new Date()
    const monthName = months[lang][currentDate.getMonth()]
    const day = currentDate.getDate()
    const year = currentDate.getFullYear()

    const formattedDate = `${monthName} ${day}, ${year}`
    return formattedDate
  }

  const getTimeFromDate = (date: string | Date) => {
    const dateTime = new Date(date)
    let hour = dateTime.getHours()
    const minutes = dateTime.getMinutes()
    const amOrPm = hour >= 12 ? 'pm' : 'am'

    if (hour > 12) {
      hour -= 12
    }

    return `${hour}:${
      !minutes ? '00' : minutes >= 10 ? minutes : '0' + minutes
    } ${amOrPm}`
  }

  const manipulateDate = (settings: ManipulateDate) => {
    const { date, params } = settings
    const { days = 0, hours = 0, minutes = 0, months = 0, years = 0 } = params

    const newDate = new Date(date)

    newDate.setDate(newDate.getDate() + days)
    newDate.setMonth(newDate.getMonth() + months)
    newDate.setHours(newDate.getHours() + hours)
    newDate.setMinutes(newDate.getMinutes() + minutes)
    newDate.setFullYear(newDate.getFullYear() + years)

    return newDate.toString()
  }

  const handleFormatDate = (date: Date | string, formatOptions?: string) =>
    date ? format(new Date(date), formatOptions ?? 'MM/dd/yyyy hh:mm a') : ''

  const zonedTimeToUtc = (date: Date, options?: TZoneTimeOptions) => {
    const utcDate = date.toISOString()

    if (options?.removeTimeZone) {
      return utcDate.replace('T', ' ').replace('Z', '').split('.')[0]
    }

    return date
  }

  return {
    monthRange,
    currentYear,
    currentMonth,
    currentDay,
    zonedTimeToUtc,
    getCurrentDate,
    getTimeFromDate,
    manipulateDate,
    handleFormatDate,
  }
}
