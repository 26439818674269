import type { BaseServiceOptions, Pagination } from '@/core/types'
import {
  type TPurchaseOrderDetail,
  moduleDetailServiceHandlers,
  serviceConfig,
  catalogServiceHandlers,
  TArticle,
} from '../../..'
import { useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig.details

export const usePurchaseDetailsQuery = (
  options: BaseServiceOptions<Pagination>,
) => {
  const { currentPage, enableQuery, filters, modelId } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TPurchaseOrderDetail>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TPurchaseOrderDetail>(
    (payload) =>
      moduleDetailServiceHandlers.deleteRecord({
        modelChildId: payload.id,
        modelId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<
    TPurchaseOrderDetail,
    AxiosError,
    TPurchaseOrderDetail
  >(
    (payload: TPurchaseOrderDetail) =>
      moduleDetailServiceHandlers.editRecord(payload, {
        modelChildId: payload.id,
        modelId,
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<
    TPurchaseOrderDetail,
    AxiosError,
    TPurchaseOrderDetail
  >(
    (payload: TPurchaseOrderDetail) =>
      moduleDetailServiceHandlers.addRecord(payload, { modelId }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TPurchaseOrderDetail[], AxiosError>({
    queryKey: [model, filters?.loadRecords],
    queryFn: ({ pageParam }) =>
      moduleDetailServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: pageParam,
        modelId,
      }),
    onError: (error) => errorHttpNotification(error),
    select: (data) =>
      data.map((record) => ({
        ...record,
        quantity: Number(record.quantity ?? 0).toFixed(2),
      })),
    enabled: enableQuery?.loadRecords,
    refetchOnWindowFocus: false,
  })

  const articlesCatalog = useQuery<TArticle[], AxiosError>({
    queryKey: [serviceConfig.catalogs.articles, filters?.articlesCatalog],
    queryFn: () =>
      catalogServiceHandlers.articlesCatalog.loadCatalog({
        dynamicFilters: filters?.articlesCatalog,
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.articlesCatalog,
  })

  return {
    loadRecords,
    articlesCatalog,
    addRecord,
    editRecord,
    deleteRecord,
  }
}
