<template>
  <teleport v-if="open && Boolean(anchorEl)" to="#modals-root">
    <div
      class="fixed top-0 bottom-0 left-0 right-0 z-50"
      v-bind="inheritedAttributes"
    >
      <div
        ref="popperElement"
        :class="[
          'absolute outline-none pop',
          'flex flex-col bg-surface-card shadow-lg rounded-md p-1',
        ]"
        :="$attrs"
        :style="{
          ...styles.popper,
        }"
      >
        <div
          :ref="(el) => $emit('menuRefChange', el)"
          class="overflow-y-auto overflow-x-hidden"
        >
          <slot />
        </div>
        <svg
          class="pop__tip"
          width="27"
          height="10"
          viewBox="0 0 27 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 9.5H25L21 8L14 1L6.5 8L2.5 9.5Z"
            fill="var(--surface-card)"
          />
          <path
            d="M0.75 9H3.3056C5.01943 9 6.65145 8.26711 7.79006 6.98618L12.4563 1.73669C13.2689 0.822486 14.7045 0.845389 15.4875 1.78505L19.7009 6.8411C20.8409 8.20906 22.5296 9 24.3102 9H26.7812"
            stroke="var(--surface-card)"
          />
        </svg>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
} from 'vue'
import type { CSSProperties, PropType } from 'vue'
import type { Placement } from '@popperjs/core'
import { usePopper } from './usePopper'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  name: 'PopupMenu',
  inheritAttrs: false,
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    anchorEl: {
      type: HTMLElement as PropType<HTMLElement | null | undefined>,
      default: undefined,
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'bottom-end',
    },
  },
  emits: ['close', 'menuRefChange'],
  setup(props, { attrs, emit }) {
    const popperElement = ref<HTMLElement>()

    onClickOutside(popperElement, () => emit('close'))

    const inheritedAttributes = computed(
      () =>
        ({
          style: {
            zIndex: (attrs as Record<string, CSSProperties>)?.style?.zIndex,
          },
        }) as { style: CSSProperties },
    )

    const { styles, attributes, createInstance, removeInstance } = usePopper({
      placement: props.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    })

    const createPopperInstance = () => {
      createInstance(props.anchorEl ?? undefined, popperElement.value)
    }

    onMounted(() => {
      createPopperInstance()
    })

    onUpdated(() => {
      removeInstance()
      createPopperInstance()
    })

    onBeforeUnmount(() => {
      removeInstance()
    })

    return {
      popperElement,
      styles,
      attributes,
      inheritedAttributes,
    }
  },
})
</script>

<style lang="scss">
.pop {
  &[data-popper-placement='top'] &__tip {
    @apply -bottom-[7.5px] rotate-180;
    @apply absolute left-0 right-0 mx-auto;
  }
  &[data-popper-placement='top-start'] &__tip {
    @apply -bottom-[7.5px] rotate-180;
    @apply absolute left-1;
  }
  &[data-popper-placement='top-end'] &__tip {
    @apply -bottom-[7.5px] rotate-180;
    @apply absolute right-1;
  }
  &[data-popper-placement='bottom'] &__tip {
    @apply -top-[7.5px];
    @apply absolute left-0 right-0 mx-auto;
  }
  &[data-popper-placement='bottom-start'] &__tip {
    @apply -top-[7.5px];
    @apply absolute left-1;
  }
  &[data-popper-placement='bottom-end'] &__tip {
    @apply -top-[7.5px];
    @apply absolute right-1;
  }
  &[data-popper-placement='left'] &__tip {
    @apply -right-[16px] rotate-90;
    @apply absolute top-0 bottom-0 my-auto;
    transform: rotate(-90deg);
  }
  &[data-popper-placement='left-start'] &__tip {
    @apply -right-[16px] rotate-90;
    @apply absolute top-2 my-auto;
    transform: rotate(-90deg);
  }
  &[data-popper-placement='left-end'] &__tip {
    @apply -right-[16px];
    @apply absolute bottom-2 my-auto;
    transform: rotate(90deg);
  }
  &[data-popper-placement='right'] &__tip {
    @apply -left-[16px];
    @apply absolute top-0 bottom-0 my-auto;
    transform: rotate(-90deg);
  }
  &[data-popper-placement='right-start'] &__tip {
    @apply -left-[16px];
    @apply absolute top-3 my-auto;
    transform: rotate(-90deg);
  }
  &[data-popper-placement='right-end'] &__tip {
    @apply -left-[16px];
    @apply absolute bottom-3 my-auto;
    transform: rotate(-90deg);
  }
}
</style>
