import { useQueryClient } from '@tanstack/vue-query'
import { DataWithPagination } from '../types'
import { MaybeRef } from 'vue'
import { useNotification } from '@/core/composables'

export const useServiceHandlers = <T extends { id?: number }>(settings: {
  model: string
  currentPage: MaybeRef<number>
  filterParams: MaybeRef<Record<string, string>> | MaybeRef<string>
}) => {
  const { model, currentPage, filterParams } = settings
  const queryClient = useQueryClient()

  const { successWithActionNotification } = useNotification()

  const addRecord = (payload: T) => {
    const currentData = queryClient.getQueryData<DataWithPagination<T>>([
      model,
      currentPage,
      filterParams,
    ])

    queryClient.setQueryData<DataWithPagination<T>>(
      [model, currentPage, filterParams],
      {
        ...currentData,
        data: [payload, ...(currentData?.data ?? [])],
      },
    )

    successWithActionNotification('ADDED', model)
  }

  const deleteRecord = (payload: T) => {
    const currentData = queryClient.getQueryData<DataWithPagination<T>>([
      model,
      currentPage,
      filterParams,
    ])

    queryClient.setQueryData<DataWithPagination<T>>(
      [model, currentPage, filterParams],
      {
        ...currentData,
        data: [
          ...(currentData?.data.filter((data) => data.id != payload.id) ?? []),
        ],
      },
    )
    successWithActionNotification('DELETED', model)
  }

  const deleteRecords = (payload: Array<number>) => {
    const currentData = queryClient.getQueryData<DataWithPagination<T>>([
      model,
      currentPage,
      filterParams,
    ])

    queryClient.setQueryData<DataWithPagination<T>>(
      [model, currentPage, filterParams],
      {
        ...currentData,
        data: [
          ...(currentData?.data.filter(
            (data) => !payload.includes(data?.id ?? 0),
          ) ?? []),
        ],
      },
    )

    successWithActionNotification('DELETED', model)
  }

  const updateRecord = (payload: T) => {
    const currentData = queryClient.getQueryData<DataWithPagination<T>>([
      model,
      currentPage,
      filterParams,
    ])

    queryClient.setQueryData<DataWithPagination<T>>(
      [model, currentPage, filterParams],
      {
        ...currentData,
        data: [
          ...(currentData?.data.map((data) =>
            data.id == payload.id ? { ...data, ...payload } : data,
          ) ?? []),
        ],
      },
    )

    successWithActionNotification('UPDATED', model)
  }

  const invalidateQueries = async (key?: any) => {
    await queryClient.invalidateQueries({ queryKey: [key ?? model] })
  }

  return {
    addRecord,
    deleteRecord,
    deleteRecords,
    updateRecord,
    invalidateQueries,
  }
}
