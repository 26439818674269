import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createExportEndpoint,
} from '@/core/utils'
import { TLocation } from '.'
import { DataWithPagination } from '@/core/types'
import { TWarehouse } from '../warehouses'

export type TLocations = DataWithPagination<TLocation>

export const serviceConfig = {
  model: 'locations',
  version: 'v1',
  catalogs: {
    warehouses: {
      model: 'warehouses',
      version: 'v1',
    },
  },
}

const { warehouses } = serviceConfig.catalogs

const moduleServiceHandlers = createCrudEndpoints<TLocations, TLocation>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

const warehouseCatalog = createCatalogEndpoint<TWarehouse[]>(
  warehouses.model,
  warehouses.version,
)

const catalogServiceHandlers = {
  warehouseCatalog,
}

export { moduleServiceHandlers, moduleExportHandlers, catalogServiceHandlers }
