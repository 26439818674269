import { RouteRecordRaw } from 'vue-router'
import { authModuleRoutes } from '@/modules/auth'

const AuthLayout = () =>
  import(/* webpackChunkName: "login-layout" */ '@/core/layouts/AuthLayout.vue')

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/auth/',
    component: AuthLayout,
    redirect: '/login',
    children: [...authModuleRoutes],
  },
]
