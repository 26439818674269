import api from '@/core/config/axios'
import type {
  ActionPayload,
  DeletePayload,
  TFilters,
  ShowPayload,
  UpdatePayload,
} from '../types'
import { AxiosRequestConfig } from 'axios'
import { MaybeRef, toValue } from 'vue'

type NestedParams = {
  modelId?: MaybeRef<number | string>
  modelChildId?: MaybeRef<number | string>
}

export const createNestedCrudEndpoints = <TModelResponse, TModel>(
  model: string,
  childModel: string,
  version?: string,
) => {
  const loadRecords = async (
    params: TFilters & NestedParams,
  ): Promise<TModelResponse> => {
    const urlSearchParams = [
      `page=${toValue(params.page ?? 1)}`,
      toValue(params.dynamicFilters ?? ''),
    ]
      .filter(Boolean)
      .join('&')

    return api
      .get(
        `${version ?? ''}/${model}/${toValue(
          params.modelId,
        )}/${childModel}?${urlSearchParams}`,
      )
      .then((response) => response.data)
  }

  const loadRecord = async (
    payload: ShowPayload,
    params?: NestedParams,
  ): Promise<TModel> =>
    api
      .get(
        `${version ?? ''}/${model}/${toValue(params?.modelId)}/${childModel}/${
          payload.id
        }`,
      )
      .then((response) => response.data)

  const addRecord = async (
    payload: TModel | FormData,
    params?: NestedParams,
    config?: AxiosRequestConfig<TModel>,
  ): Promise<TModel> =>
    api
      .post(
        `${version ?? ''}/${model}/${toValue(params?.modelId)}/${childModel}`,
        payload,
        config,
      )
      .then((response) => response.data)

  const editRecordFormData = async (
    payload: (TModel & UpdatePayload) | FormData,
    params?: NestedParams,
    config?: AxiosRequestConfig<TModel & UpdatePayload>,
  ): Promise<TModel> =>
    await api
      .post(
        `${version ?? ''}/${model}/${toValue(
          params?.modelId,
        )}/${childModel}/${toValue(params?.modelChildId)}`,
        payload,
        config,
      )
      .then((response) => response.data)

  const editRecord = async (
    payload: (TModel & UpdatePayload) | FormData,
    params?: NestedParams,
    config?: AxiosRequestConfig<TModel & UpdatePayload>,
  ): Promise<TModel> =>
    await api
      .put(
        `${version ?? ''}/${model}/${toValue(
          params?.modelId,
        )}/${childModel}/${toValue(params?.modelChildId)}`,
        payload,
        config,
      )
      .then((response) => response.data)

  const deleteRecord = async (params?: NestedParams): Promise<void> =>
    await api
      .delete(
        `${version ?? ''}/${model}/${toValue(
          params?.modelId,
        )}/${childModel}/${toValue(params?.modelChildId)}`,
      )
      .then((response) => response.data)

  const deleteRecords = async (
    payload: DeletePayload,
    params?: NestedParams,
  ): Promise<void> =>
    api
      .delete(
        `${version ?? ''}/${model}/${toValue(
          params?.modelId,
        )}/${childModel}/delete`,
        {
          params: {
            ids: payload.ids,
          },
        },
      )
      .then((response) => response.data)

  const actionRecord = async (
    payload: ActionPayload,
    params: NestedParams,
    data?: any,
  ): Promise<TModel> =>
    api
      .put(
        `${version ?? ''}/${model}/${toValue(
          params?.modelId,
        )}/${childModel}/${toValue(params?.modelChildId)}/${payload.action}`,
        data,
      )
      .then((response) => response.data)

  const importRecords = async (
    payload: FormData,
    params: NestedParams,
  ): Promise<void> =>
    api.post(
      `${version ?? ''}/${model}/${params.modelId}/${childModel}-import`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

  return {
    loadRecords,
    loadRecord,
    addRecord,
    editRecord,
    editRecordFormData,
    deleteRecord,
    deleteRecords,
    actionRecord,
    importRecords,
  }
}
