import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createExportEndpoint,
} from '@/core/utils'
import { TMovementRequest, TStocks } from '.'
import { DataWithPagination } from '@/core/types'
import { TWarehouse } from '../warehouses'
import { TArticle } from '../articles'
import { TLocation } from '../locations'
import api from '@/core/config/axios'
import { MaybeRef } from 'vue'

export type TMovementRequests = DataWithPagination<TMovementRequest>

export const serviceConfig = {
  model: 'movement-requests',
  version: 'v1',
  catalogs: {
    articles: {
      model: 'articles',
      version: 'v1',
    },
    warehouses: {
      model: 'warehouses',
      version: 'v1',
    },
    locations: {
      model: 'locations',
      version: 'v1',
    },
    stock: {
      model: 'stock',
      version: 'v1',
    },
  },
}

const { warehouses, articles, locations, stock } = serviceConfig.catalogs

const moduleServiceHandlers = {
  ...createCrudEndpoints<TMovementRequests, TMovementRequest>(
    serviceConfig.model,
    serviceConfig.version,
  ),
  addRecord: async (payload: TMovementRequest): Promise<TMovementRequest> =>
    api
      .post(
        `${serviceConfig.version ?? ''}/${serviceConfig.model}/entry`,
        payload,
      )
      .then((response) => response.data),
  addRecordOutput: async (
    payload: TMovementRequest,
  ): Promise<TMovementRequest> =>
    api
      .post(
        `${serviceConfig.version ?? ''}/${serviceConfig.model}/output`,
        payload,
      )
      .then((response) => response.data),
  loadUnitPrice: async (payload: {
    id?: MaybeRef<number | string>
  }): Promise<number> =>
    api
      .get(
        `${serviceConfig.version ?? ''}/catalog/${
          serviceConfig.catalogs.stock.model
        }/${payload.id}/unit-price`,
      )
      .then((response) => response.data),
}

const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

const articlesCatalog = createCatalogEndpoint<TArticle[]>(
  articles.model,
  articles.version,
)
const warehouseCatalog = createCatalogEndpoint<TWarehouse[]>(
  warehouses.model,
  warehouses.version,
)
const locationsCatalog = createCatalogEndpoint<TLocation[]>(
  locations.model,
  locations.version,
)
const stockCatalog = createCatalogEndpoint<TStocks[]>(
  stock.model,
  stock.version,
)

const catalogServiceHandlers = {
  articlesCatalog,
  warehouseCatalog,
  locationsCatalog,
  stockCatalog,
}

export { moduleServiceHandlers, moduleExportHandlers, catalogServiceHandlers }
