import { computed, ref } from 'vue'
import { createInjectionState, toReactive } from '@vueuse/core'
import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import type { THistory } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { Pagination } from '@/core/types'

const [useProvideModule, useModule] = createInjectionState(() => {
  const form = ref<THistory>({})
  const modelId = ref<number>()

  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    relations: {
      stock: {
        inventoriable_id: '',
        warehouse_id: '',
      },
    },
    order_by: 'created_at,desc',
  })

  const materialsFilters = ref<ToQueryStringFilters>({
    param: '',
    status_id: [1],
    order_by: 'created_at,desc',
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['movement_type', 'reason', 'id'],
  })

  const materialsQueryParams = useToQueryString(toReactive(materialsFilters), {
    searchColumns: ['id', 'part_number', 'name'],
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    modelId: computed(() => Number(modelId.value)),
    filters: {
      loadRecords: moduleQueryParams.queryParams,
      articlesCatalog: materialsQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
      articlesCatalog: true,
      loadRecord: computed(() => !!modelId.value),
    },
    handlers: {
      onDataChange: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const onLoadRecord = () => {
    modelId.value = form.value.id
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)
  const detail = computed(() => moduleQuery.loadRecord.data?.value)
  const warehouse = computed(() => moduleQuery.warehouseCatalog?.data?.value)
  const articles = computed(() => moduleQuery.articlesCatalog.data?.value)
  const isArticlesLoading = computed(
    () => moduleQuery.articlesCatalog.isLoading.value,
  )
  const isLoading = computed(() => moduleQuery.loadRecords.isLoading.value)

  return {
    tableData,
    detail,
    filters,
    orderByOptions,
    form,
    isLoading,
    warehouse,
    materialsFilters,
    articles,
    isArticlesLoading,
    modulePagination: toReactive(modulePagination),
    onLoadRecord,
  }
})

export { useProvideModule, useModule }
