import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, WAREHOUSEMAN } = authRoles

const Locations = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

export const locationsModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/locations',
    name: 'locations',
    component: Locations,
    meta: {
      title: 'Localidades',
      auth: {
        roles: [ADMIN, WAREHOUSEMAN],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
