import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createExportEndpoint,
} from '@/core/utils'
import { TArticle, TCategory, TMetric, TType } from '.'
import { DataWithPagination } from '@/core/types'

export type TArticles = DataWithPagination<TArticle>

export const serviceConfig = {
  model: 'articles',
  version: 'v1',
  catalogs: {
    categories: {
      model: 'categories',
      version: 'v1',
    },
    types: {
      model: 'types',
      version: 'v1',
    },
    metrics: {
      model: 'metrics',
      version: 'v1',
    },
  },
}

const { categories, metrics, types } = serviceConfig.catalogs

const moduleServiceHandlers = createCrudEndpoints<TArticles, TArticle>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandlers = createExportEndpoint(serviceConfig.model)

const categoriesCatalog = createCatalogEndpoint<TCategory[]>(
  categories.model,
  categories.version,
)

const typesCatalog = createCatalogEndpoint<TType[]>(types.model, types.version)

const metricsCatalog = createCatalogEndpoint<TMetric[]>(
  metrics.model,
  metrics.version,
)

const catalogServiceHandlers = {
  categoriesCatalog,
  typesCatalog,
  metricsCatalog,
}

export { moduleServiceHandlers, moduleExportHandlers, catalogServiceHandlers }
