import type {
  BaseServiceOptions,
  Pagination,
  TBaseFileReception,
  TRecordAction,
} from '@/core/types'
import {
  type TReception,
  moduleServiceHandlers,
  serviceConfig,
  type TReceptions,
  TReceptionDetail,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model } = serviceConfig

export const useModuleQuery = (
  options: BaseServiceOptions<Pagination, TReception>,
) => {
  const { currentPage, modelId, handlers, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TReception>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<
    TReception & TBaseFileReception,
    AxiosError,
    TReception
  >(
    (payload: TReception & TBaseFileReception) =>
      moduleServiceHandlers.editRecordFormData(
        modelToFormData(payload, 'PUT'),
        payload.id,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<
    TReception & TBaseFileReception,
    AxiosError,
    TReception
  >(
    (payload: TReception & TBaseFileReception) =>
      moduleServiceHandlers.addRecord(modelToFormData(payload), {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TReception, AxiosError, TRecordAction>(
    (payload: TRecordAction) =>
      moduleServiceHandlers.actionRecord({
        ...payload,
        data: payload.data ? transformReceptionDetails(payload.data) : {},
      }),
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TReceptions, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onDataChange?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const loadRecord = useQuery<TReception, AxiosError>({
    queryKey: [model, modelId],
    queryFn: ({ queryKey }) =>
      moduleServiceHandlers.loadRecord({
        id: Number(queryKey[1]),
      }),
    refetchOnWindowFocus: false,
    enabled: enableQuery?.loadRecord,
    onError: (error) => errorHttpNotification(error),
    onSuccess: (data) => {
      if (data.id) {
        handlers?.onLoadRecord?.(data)
      }
    },
  })

  const transformReceptionDetails = (data: TReceptionDetail[]) => ({
    details: data.map((record: TReceptionDetail) => ({
      id: record?.id,
      to_receive: record?.to_receive,
    })),
  })

  const modelToFormData = (
    payload: TReception & TBaseFileReception,
    method?: string,
  ) => {
    const formData = new FormData()
    formData.append(
      'purchase_order_id',
      payload?.purchase_order_id?.toString() ?? '',
    )
    formData.append('warehouse_id', payload?.warehouse_id?.toString() ?? '')

    formData.append('certificate_note', payload.certificate?.note ?? '')
    formData.append('certificate_folio', payload.certificate?.folio ?? '')

    if (payload?.file) {
      formData.append('certificate_file', payload.file)
    }

    if (payload?.folio) {
      formData.append('folio', payload.folio.toString())
    }

    if (method) {
      formData.append('_method', method)
    }

    return formData
  }

  return {
    loadRecord,
    loadRecords,
    actionRecord,
    editRecord,
    addRecord,
  }
}
